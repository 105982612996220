import clsx from 'clsx'
import classes from './styles.module.scss'

interface Props {
  href?: string
  newTab?: boolean
  className?: string
  underlineOnHover?: boolean
  onClick?: (e: any) => void
  ariaLabel?: string
  children?: React.ReactNode
}

function AppTypographyLink(props: Props) {
  const {
    href,
    className,
    newTab,
    underlineOnHover,
    children,
    onClick,
    ariaLabel,
  } = props

  const combinedClasses = clsx(
    classes.appTypographyLink,
    className ? className : 'text-gray-600', //default color
    underlineOnHover ? classes.underlineOnHover : undefined,
  )
  const hrefProps = {
    'aria-label': ariaLabel ? ariaLabel : undefined,
    ...(newTab ? {target: '_blank', rel: 'noopener noreferrer'} : {}),
  }

  return (
    <a href={href} {...hrefProps} className={combinedClasses} onClick={onClick}>
      {children}
    </a>
  )
}

export default AppTypographyLink
