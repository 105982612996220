import {useState} from 'react'
import {useForm, Resolver, FieldError} from 'react-hook-form'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppTextField from 'src/components/forms/textField/AppTextField'
import AppTypography from 'src/components/elements/typography/AppTypography'
import CheckBoxIcon from 'src/components/elements/icons/CheckBox'
import {publicUpdateUserPasswordByTokenApi} from 'src/services/api/auth'
import {ValidationError, fieldError} from 'src/hooks/form'
import {
  validatePassword,
  validateConfirmPassword,
} from 'src/services/validation/validateModules'
import {useStyles} from './styles'
import clsx from 'clsx'
import InputError from 'src/components/elements/helpers/InputError'

type FormValues = {
  password: string
  confirm_password: string
}

const validate: Omit<
  Record<keyof FormValues, (value: string) => string | undefined>,
  'confirm_password'
> = {
  password: validatePassword,
}

export default function StepTwoSection() {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [success, setSuccess]: any = useState<boolean>(false)
  const [serverError, setServerError] = useState<ValidationError<any>>(null)
  const [notFoundError, setNotFoundError] = useState<boolean>(false)

  const {t} = useTranslation('auth')
  const router = useRouter()
  const token = String(router.query.token)

  const classes = useStyles()

  const formResolver: Resolver<FormValues> = async (values: FormValues) => {
    const errors: Record<string, FieldError> = {}
    const {confirm_password, password} = values
    for (let field in values) {
      const key = field as keyof FormValues
      const message =
        key === 'confirm_password'
          ? validateConfirmPassword(confirm_password, password)
          : validate[key](values[key])
      if (message) {
        errors[key] = {
          type: '',
          message: t(message),
        }
      }
    }

    return {
      errors,
      values,
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: formResolver,
  })

  const passwordError = fieldError('password', {
    form: errors,
    response: serverError,
  })
  const repeatPasswordError = fieldError('confirm_password', {
    form: errors,
    response: serverError,
  })

  const onSubmit = (formData: FormValues) => {
    setServerError(null)
    setSuccess(false)
    setSubmitting(true)
    setNotFoundError(false)

    publicUpdateUserPasswordByTokenApi(
      formData.password,
      formData.confirm_password,
      token,
    )
      .then(() => {
        setSuccess(true)
        setValue('password', '')
        setValue('confirm_password', '')
      })
      .catch((ex) => {
        if (ex.response.status === 404) {
          setNotFoundError(true)
        }
        setServerError(ex.response.data)
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={clsx(classes.form, 'flex flex-col')}>
        <Success success={success} />
        <AppTextField
          label={t('New Password')}
          placeholder={t('at least 8 characters')}
          type="password"
          error={Boolean(passwordError)}
          message={passwordError}
          inputProps={register('password', {
            required: `${t('New password is required')}`,
          })}
        />
        <AppTextField
          label={t('Repeat New Password')}
          placeholder={t('at least 8 characters')}
          type="password"
          error={Boolean(repeatPasswordError)}
          message={repeatPasswordError}
          inputProps={register('confirm_password', {
            required: `${t('Password confirmation is required')}`,
          })}
        />
        <Error error={notFoundError} />
        <AppButton
          variant="contained"
          color="primary"
          fullWidth
          className="mb-5"
          type="submit"
          disabled={submitting}
        >
          {t('Confirm')}
        </AppButton>
      </div>
    </form>
  )
}

function Success(props: {success: boolean}) {
  const classes = useStyles()
  const {t} = useTranslation('auth')

  if (!props.success) {
    return null
  }

  return (
    <div className={clsx(classes.success, 'flex flex-row')}>
      <CheckBoxIcon checked />
      <AppTypography variant="action" className={classes.message}>
        {t('Password successfully changed!')}
      </AppTypography>
    </div>
  )
}

function Error(props: {error: boolean}) {
  if (!props.error) {
    return null
  }

  return <InputError>Not found</InputError>
}
