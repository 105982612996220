import clsx from 'clsx'

export default function AppDivider(props: {
  className?: string
  darker?: boolean
}) {
  return (
    <div
      className={clsx(
        `flex w-full min-h-px h-px `,
        props.className || '',
        props.darker ? 'bg-gray-500' : 'bg-divider',
      )}
    />
  )
}
