import {OrderType} from 'src/types/search'
import {http} from 'src/services/http'
import {GuestSelectType} from 'src/types/guestSelect'
import {CancelToken} from 'axios'
import {
  BookingDiscountInfoType,
  BookingListType,
  BookingModificationType,
  BookingPromoCodeType,
  ProtectGroupExtraFeeType,
} from 'src/types/booking'
import {SelectDateType} from 'src/types/utils'
import {Dayjs} from 'src/utils/date'

export type RequestSearchType = {
  pending?: boolean
  chargeFailed?: boolean
  balanceIssue?: boolean
  accepted?: boolean
  preAccepted?: boolean
  canceled?: boolean
  rejected?: boolean
  toReview?: boolean
  passed?: boolean
  ongoing?: boolean
  expired?: boolean
  contested?: string | null
  protectGroupAccepted?: boolean
  search?: string | null
  order?: OrderType
  orderBy?: string
  page?: number
  limit?: number
  dateRange?: SelectDateType | null
  dateSearchType?: string
  propertyId?: number
  userId?: number
  tagId?: number | null
  color?: string
}

type RequestBookingAvailableType = {
  start: string | Dayjs
  end: string | Dayjs
  locale: string
  options: GuestSelectType | null
  user_id: number | null //use by admin
}

type RequestBookingInformationsType = {
  start: string | Dayjs
  end: string | Dayjs
  options: GuestSelectType | null
  locale: string
  promo_code: BookingPromoCodeType | null
  discount: BookingDiscountInfoType | null
  extra_fees: {protect_group?: ProtectGroupExtraFeeType} | null
}

type CreateRequestBookingInformationsType = RequestBookingInformationsType & {
  first_message: string
  is_request_information: boolean
}
type AdminCreateRequestBookingInformationsType =
  CreateRequestBookingInformationsType & {
    user_id: number | null //use by admin to create booking for other people
    payment_mode: 'stripe' | 'outside'
  }
type UpdateRequestBookingInformationsType = RequestBookingInformationsType & {
  arriving_time: string
  departure_time: string
  use_property_prices: boolean
  admin_notes?: string | null
}

export async function createBookingApi(
  propertyId: number,
  request: CreateRequestBookingInformationsType,
) {
  const {data} = await http.post(`/property/${propertyId}/bookings`, request)

  return data
}

export async function adminCreateBookingApi(
  propertyId: number,
  request: AdminCreateRequestBookingInformationsType,
) {
  const {data} = await http.post(
    `/admin/property/${propertyId}/bookings`,
    request,
  )

  return data
}

export async function adminUpdateBookingApi(
  bookingId: number,
  request: UpdateRequestBookingInformationsType,
) {
  const {data} = await http.put(`/admin/booking/${bookingId}`, request)

  return data
}

export async function getPreviewModifiedBookingApi(
  bookingId: number,
  request: UpdateRequestBookingInformationsType,
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    url: `/booking/${bookingId}/preview_modified_prices`,
    method: 'post',
    data: request,
    cancelToken,
  })

  return data
}

export async function publicCheckBookingAvailableApi(
  propertyId: number,
  {start, end, options, locale, user_id}: RequestBookingAvailableType,
) {
  const {data} = await http.post(
    `/public/property/${propertyId}/check_booking_available`,
    {
      start,
      end,
      options,
      locale,
      user_id,
    },
  )

  return data
}

export async function checkBookingAvailableAdvancedApi(
  propertyId: number,
  {start, end, options, locale, user_id}: RequestBookingAvailableType,
) {
  const {data} = await http.post(
    `/property/${propertyId}/check_booking_available_advanced`,
    {
      start,
      end,
      options,
      locale,
      user_id,
    },
  )

  return data
}

export async function getMyBookingsApi(
  searchProps?: RequestSearchType,
  cancelToken?: CancelToken,
) {
  const searchData: RequestSearchType = {
    pending: searchProps?.pending,
    chargeFailed: searchProps?.chargeFailed,
    balanceIssue: searchProps?.balanceIssue,
    accepted: searchProps?.accepted,
    preAccepted: searchProps?.preAccepted,
    canceled: searchProps?.canceled,
    rejected: searchProps?.rejected,
    toReview: searchProps?.toReview,
    passed: searchProps?.passed,
    ongoing: searchProps?.ongoing,
    expired: searchProps?.expired,
    contested: searchProps?.contested,
    order: searchProps?.order,
    orderBy: searchProps?.orderBy,
    dateRange: searchProps?.dateRange,
    dateSearchType: searchProps?.dateSearchType,
    tagId: searchProps?.tagId,
    color:
      searchProps?.color === 'all' || !searchProps?.color
        ? undefined
        : searchProps?.color,
    page: searchProps?.page,
    limit: searchProps?.limit,
  }

  if (searchProps?.search) {
    searchData.search = searchProps?.search
  }

  const {data} = await http.request({
    method: 'post',
    url: '/guest/bookings',
    data: searchData,
    cancelToken,
  })

  return data as BookingListType
}

export async function getReservationsApi(
  {
    pending,
    chargeFailed,
    balanceIssue,
    accepted,
    preAccepted,
    canceled,
    rejected,
    toReview,
    passed,
    ongoing,
    expired,
    contested,
    propertyId,
    search,
    order,
    orderBy,
    dateRange,
    dateSearchType,
    tagId,
    color,
    page = 1,
    limit = 50,
  }: RequestSearchType,
  cancelToken?: CancelToken,
) {
  const searchData: RequestSearchType = {
    pending,
    accepted,
    preAccepted,
    chargeFailed,
    balanceIssue,
    canceled,
    rejected,
    toReview,
    passed,
    ongoing,
    expired,
    contested,
    order,
    orderBy,
    dateRange,
    dateSearchType,
    tagId,
    color: color === 'all' || !color ? '' : color,
    page,
    limit,
  }
  if (propertyId) {
    searchData.propertyId = propertyId
  }

  if (search) {
    searchData.search = search
  }

  const {data} = await http.request({
    method: 'post',
    url: '/host/bookings',
    data: searchData,
    cancelToken,
  })

  return data as BookingListType
}

export async function adminGetAllBookingsApi(
  {
    pending,
    accepted,
    preAccepted,
    chargeFailed,
    balanceIssue,
    canceled,
    rejected,
    toReview,
    passed,
    ongoing,
    expired,
    contested,
    propertyId,
    userId,
    search,
    order,
    orderBy,
    dateRange,
    dateSearchType,
    protectGroupAccepted,
    tagId,
    color,
    page = 1,
    limit = 50,
  }: RequestSearchType,
  cancelToken?: CancelToken,
) {
  const searchData: RequestSearchType = {
    pending,
    preAccepted,
    accepted,
    canceled,
    rejected,
    toReview,
    passed,
    chargeFailed,
    balanceIssue,
    ongoing,
    expired,
    contested,
    order,
    orderBy,
    dateRange,
    dateSearchType,
    protectGroupAccepted,
    tagId,
    color: color === 'all' || !color ? '' : color,
    page,
    limit,
  }
  if (propertyId) {
    searchData.propertyId = propertyId
  }

  if (userId) {
    searchData.userId = userId
  }

  if (search) {
    searchData.search = search
  }

  const {data} = await http.request({
    method: 'post',
    url: '/admin/bookings',
    data: searchData,
    cancelToken,
  })

  return data as BookingListType
}

export async function getMessageMasterApi(bookingId: string) {
  const {data} = await http.get(`/booking/${bookingId}/message_master`)

  return data
}

export async function cancelBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/cancel`)

  return data
}

export async function acceptBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/accept`)

  return data
}

export async function rejectBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/reject`)

  return data
}

export async function publicGetBookingPriceApi(
  searchData: {
    propertyId: number
    start: string
    end: string
    options: GuestSelectType
    discount?: BookingDiscountInfoType
    extra_fees?: {
      protect_group?: ProtectGroupExtraFeeType
    }
  },
  cancelToken?: CancelToken,
) {
  const {data} = await http.request({
    method: 'post',
    url: `/public/property/${searchData.propertyId}/booking_price`,
    data: {
      start: searchData.start,
      end: searchData.end,
      options: searchData.options,
      discount: searchData.discount,
      extra_fees: searchData.extra_fees,
    },
    cancelToken,
  })

  return data
}

export async function adminEnableSecurityDepositRefundApi(bookingId: number) {
  const {data} = await http.put(
    `/admin/booking/${bookingId}/enable_security_deposit_refund`,
  )

  return data
}

export async function adminDisableSecurityDepositRefundApi(bookingId: number) {
  const {data} = await http.put(
    `/admin/booking/${bookingId}/disable_security_deposit_refund`,
  )

  return data
}

export async function adminEnableSecurityDepositApi(bookingId: number) {
  const {data} = await http.put(
    `/admin/booking/${bookingId}/enable_security_deposit`,
  )

  return data
}

export async function adminDisableSecurityDepositApi(bookingId: number) {
  const {data} = await http.put(
    `/admin/booking/${bookingId}/disable_security_deposit`,
  )

  return data
}

export async function setBookingCheckedApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/checked_by_host`)

  return data
}

export async function retryPaymentApi(bookingId: number) {
  const {data} = await http.post(`/booking/${bookingId}/retry_payment`)

  return data
}

export async function cancelByHostApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/cancel_by_host`)

  return data
}

export async function cancelPreAcceptedBookingByHostApi(bookingId: number) {
  const {data} = await http.put(
    `/booking/${bookingId}/cancel_pre_accepted_by_host`,
  )

  return data
}

export async function adminCancelByAdminApi(bookingId: number, reason: string) {
  const {data} = await http.put(`/admin/booking/${bookingId}/cancel_by_admin`, {
    reason,
  })

  return data
}

export async function adminCancelByAdminAsGuestApi(
  bookingId: number,
  reason: string,
) {
  const {data} = await http.put(
    `/admin/booking/${bookingId}/cancel_by_admin_as_guest`,
    {reason},
  )

  return data
}

export async function getBookingByUidApi(bookingUid: string) {
  const {data} = await http.get(`/booking/uid/${bookingUid}`)

  return data
}

export async function getCancelPricesApi(bookingId: number) {
  const {data} = await http.get(`/booking/${bookingId}/get_cancel_prices`)

  return data
}

export async function sendCustomPricingApi(bookingId: number, amount: number) {
  const {data} = await http.post(`/booking/${bookingId}/send_custom_pricing`, {
    amount,
  })

  return data
}

export async function acceptCustomPricingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/accept_custom_pricing`)

  return data
}

export async function declineCustomPricingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/reject_custom_pricing`)

  return data
}

export async function cancelCustomPricingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/cancel_custom_pricing`)

  return data
}

export async function requestMoneyApi(bookingId: number, price: number) {
  const {data} = await http.post(`/booking/${bookingId}/request_money`, {
    amount: price,
  })

  return data
}

export async function adminUpdateRequestMoneyApi(
  bookingId: number,
  requestMoneyId: number,
  requestData: {amount?: number; admin_force_processed?: boolean},
) {
  const {data} = await http.put(
    `/booking/${bookingId}/request_money/${requestMoneyId}`,
    requestData,
  )

  return data
}

export async function acceptRequestMoneyApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/accept_request_money`)

  return data
}

export async function declineRequestMoneyApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/reject_request_money`)

  return data
}

export async function getBookingPricesWithCustomPricingApi(bookingId: number) {
  const {data} = await http.get(
    `/booking/${bookingId}/booking_prices_with_custom_pricing`,
  )

  return data
}

export async function bookNowApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/book_now`)

  return data
}

export async function preAcceptBookingApi(bookingId: number) {
  const {data} = await http.put(`/booking/${bookingId}/pre_accept`)

  return data
}

export async function getBookingHistoryApi(bookingId: number) {
  const {data} = await http.get(`/booking/${bookingId}/history`)

  return data
}

export async function setBookingHistoryApi(
  bookingId: number,
  description: string | null,
) {
  const {data} = await http.post(`/booking/${bookingId}/history`, {
    description,
  })

  return data
}

export async function getBookingHistoryAttachmentsApi(bookingId: number) {
  const {data} = await http.get(`/booking/${bookingId}/history_attachments`)

  return data
}

export async function addBookingHistoryAttachmentApi(
  bookingId: number,
  file: FormData,
) {
  const {data} = await http.post(
    `/booking/${bookingId}/history_attachments`,
    file,
  )

  return data
}

export async function removeBookingHistoryAttachmentApi(
  bookingHistoryAttachmentId: number,
) {
  const {data} = await http.delete(
    `/booking_history_attachment/${bookingHistoryAttachmentId}`,
  )

  return data
}

export async function getBookingInvoice(bookingUid: string) {
  const {data} = await http.get(`/booking/${bookingUid}/invoice`, {
    responseType: 'blob', // important
  })

  return data
}

export async function adminUpdateBookingColorApi(
  bookingId: number,
  color: string,
) {
  const {data} = await http.put(`/admin/booking/${bookingId}/color`, {color})

  return data
}

export async function adminGetChargeInfosApi(bookingId: number) {
  const {data} = await http.get(`/admin/booking/${bookingId}/charge_infos`)

  return data
}

export async function adminGetPaymentMethodsApi(bookingId: number) {
  const {data} = await http.get(`/admin/booking/${bookingId}/payment_methods`)

  return data
}

export async function adminUpdateBookingLeadApi(
  bookingId: number,
  lead: string | null,
) {
  const {data} = await http.put(`/admin/booking/${bookingId}/lead`, {lead})

  return data
}

export async function adminUpdateBookingMentionApi(
  bookingId: number,
  mention: string | null,
) {
  const {data} = await http.put(`/admin/booking/${bookingId}/mention`, {
    mention,
  })

  return data
}

export async function adminUpdateBookingCategoryApi(
  bookingId: number,
  category: string | null,
) {
  const {data} = await http.put(`/admin/booking/${bookingId}/category`, {
    category,
  })

  return data
}

export async function adminGetBookingModificationsApi(bookingId: number) {
  const {data} = await http.get(`/admin/booking/${bookingId}/modifications`)

  return data as BookingModificationType[]
}

export async function adminSendBookingNotificationApi(
  bookingId: number,
  notification:
    | 'instructions'
    | 'arrival'
    | 'credit-card-issue'
    | 'departure'
    | 'payment-confirmation'
    | 'host-payment-confirmation'
    | 'security-deposit-refund'
    | 'security-deposit-taken',
) {
  const {data} = await http.post(`/admin/notifications/booking/${bookingId}`, {
    notification,
  })

  return data as {success: boolean}
}

export async function adminSendBookingAction(
  bookingId: number,
  action: 'security-deposit-charge' | 'instructions-email' | 'sync-with-stripe',
) {
  const {data} = await http.post(`/admin/actions/booking/${bookingId}`, {
    action,
  })

  return data as {success: boolean}
}
