import {AnyAction} from 'redux'
import {FETCHING_API_DATA, LOGOUT} from 'src/store/actionTypes'

type AccountSettingStateType = {
  fetchingData: boolean
}
const initialState: AccountSettingStateType = {
  fetchingData: false,
}

const accountSettingsReducer = (
  state = initialState,
  action: AnyAction,
): AccountSettingStateType => {
  switch (action.type) {
    case LOGOUT:
      return {...state}
    case FETCHING_API_DATA: {
      return {...state, fetchingData: action.payload}
    }
    default:
      return state
  }
}

export default accountSettingsReducer
