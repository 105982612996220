import {http} from 'src/services/http'
import {DataCollectionType} from 'src/types/data'

export async function publicAddDataCollectionApi(
  type: DataCollectionType,
  data?: Object,
) {
  const {data: result} = await http.post(`/public/data`, {
    type,
    data,
  })

  return result
}
