import axios from 'axios'
import {BASE_URL} from 'src/constants/route'
import {http} from 'src/services/http'

export async function fetchBuildIdApi() {
  const data = await axios.get(`${BASE_URL}/api/build_id`)
  try {
    return data.data.buildId
  } catch (e) {
    return null
  }
}

export async function publicContactUsApi(data: FormData) {
  const result = await http.post(`/public/contact_us`, data)

  return result
}

export async function publicAgencyFormApi(data: FormData) {
  const result = await http.post(`/public/agency_form`, data)

  return result
}
