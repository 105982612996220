import {useCallback} from 'react'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AppModal from 'src/components/elements/AppModal'
import {useCurrentLocale} from 'src/hooks/locale'
import {convertPathnameWithLocale} from 'src/utils/locale'
import {useAppContext} from 'src/context/AppProvider'
import {DEFAULT_LOCALE, LOCALE_EN, LOCALE_FR} from 'src/constants/locale'
import {LocaleType} from 'src/types/utils'
import {HOME_PAGE} from 'src/constants/route'
import {useRouterLocationPathname} from 'src/hooks/other'
import AppTypography from 'src/components/elements/typography/AppTypography'
import Button from '@material-ui/core/Button'

export default function LanguagesDialog(props: {
  open: boolean
  onClose: () => void
}) {
  const {open, onClose} = props

  const {currentPageBuilderPage} = useAppContext()
  const {t} = useTranslation('header')
  const router = useRouter()
  const {locale} = router
  const currentLocale = useCurrentLocale()
  const completePathname = useRouterLocationPathname()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleChangeLanguage = (localeWanted: LocaleType) => async () => {
    if (localeWanted === locale) {
      return
    }

    //dont save update lang on switch, we will do it when the next locale url is loading in <OnLoadContainer
    let finalPathname =
      (currentLocale !== DEFAULT_LOCALE ? `/${currentLocale}` : '') +
      (completePathname === HOME_PAGE ? '/' : completePathname)

    const {newAsPath} = convertPathnameWithLocale(
      finalPathname,
      locale as string,
      localeWanted,
      {
        currentPage: currentPageBuilderPage,
      },
    )
    //force reload so we get the freshest data
    window.location.href = newAsPath + window.location.search
  }

  return (
    <AppModal
      open={open}
      size="small"
      title={t('language')}
      onClose={handleClose}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <LanguageItem
          locale={LOCALE_FR}
          onClick={handleChangeLanguage(LOCALE_FR)}
        />
        <LanguageItem
          locale={LOCALE_EN}
          onClick={handleChangeLanguage(LOCALE_EN)}
        />
      </div>
    </AppModal>
  )
}

function LanguageItem(props: {locale: LocaleType; onClick: () => void}) {
  const {locale, onClick} = props
  const currentLocale = useCurrentLocale()
  return (
    <Button
      className="appearance-none cursor-pointer border border-solid border-gray-200 px-5 py-4 flex flex-row flex-nowrap justify-between rounded-lg items-center gap-3"
      onClick={onClick}
    >
      <AppTypography
        variant="body"
        className="flex flex-row flex-nowrap justify-start items-center gap-3"
      >
        <img
          src={`/images/lang-${locale}.svg`}
          alt={`${locale} language`}
          className="text-gray-600"
        />
        {locale === LOCALE_EN ? 'English' : 'Français'}
      </AppTypography>
      {currentLocale === locale && (
        <CheckCircleIcon className="w-4 h-4 text-primary-main" />
      )}
    </Button>
  )
}
