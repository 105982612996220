import {Resolver} from 'react-hook-form'
import {PropertyType} from 'src/types/property'

import {listingStepOneResolver} from './stepOne'

export const listingResolver: Resolver<PropertyType> = async (
  values: PropertyType,
) => {
  const stepOne = await listingStepOneResolver(values)

  return {
    errors: {...stepOne.errors},
    values,
  }
}
