import {AnyAction} from 'redux'
import {
  LOGIN,
  LOGIN_BY_TOKEN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_ME,
  GET_ME_FAIL,
  GET_ME_SUCCESS,
  SWITCH_MENU,
  SET_SOCIAL_CONNECT_ERROR,
} from 'src/store/actionTypes'
import {
  deleteJwtToken,
  regenerateSessionId,
  setJwtToken,
} from 'src/services/http'
import {MICROSOFT_CLARITY_ID} from 'src/lib/gtm'
import {setUser} from '@sentry/nextjs'
import * as gtm from 'src/lib/gtm'
import {publicAddDataCollectionApi} from 'src/services/api/dataCollection'
import {ConnectedUserType} from 'src/types/user'

type UserStateType = {
  login: {
    access_token?: string | null
    user: ConnectedUserType
  } | null
  status: {
    loading: boolean
    success: boolean
    error: string | null
  }
  currentMenu: 'guest' | 'host'
  social_connect_error: string | null
}

const initialState: UserStateType = {
  login: null,
  status: {
    loading: false,
    success: false,
    error: null,
  },
  currentMenu: 'guest',
  social_connect_error: null,
}

const userReducer = (
  state = initialState,
  action: AnyAction,
): UserStateType => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        login: null,
        status: {
          loading: true,
          success: false,
          error: null,
        },
      }
    }
    case LOGIN_BY_TOKEN: {
      return {
        ...state,
        login: null,
        status: {
          loading: true,
          success: false,
          error: null,
        },
      }
    }
    case LOGIN_SUCCESS: {
      setJwtToken(action.payload.access_token)
      gtm.userLogin(action.payload.user)
      publicAddDataCollectionApi('login')

      try {
        setUser({
          email: action.payload.user.email,
          id: action.payload.user.id
            ? action.payload.user.id.toString()
            : undefined,
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      //todo make sure user has accepted analytics
      //@ts-ignore
      if (MICROSOFT_CLARITY_ID && window.clarity !== 'undefined') {
        try {
          //@ts-ignore
          window.clarity('set', 'user_id', action.payload.user.id.toString())
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }

      return {
        ...state,
        login: action.payload,
        status: {
          loading: false,
          success: true,
          error: null,
        },
      }
    }
    case LOGIN_FAIL: {
      deleteJwtToken()

      try {
        setUser(null)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      //@ts-ignore
      if (MICROSOFT_CLARITY_ID && window.clarity !== 'undefined') {
        try {
          //@ts-ignore
          window.clarity('set', 'user_id', undefined)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }

      return {
        ...state,
        login: null,
        status: {
          loading: false,
          success: false,
          error: action.payload,
        },
      }
    }
    case LOGOUT: {
      deleteJwtToken()
      regenerateSessionId() //make sure we restart session

      try {
        setUser(null)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      //id is not a sensitive data so its ok to save it
      //remove
      //@ts-ignore
      if (MICROSOFT_CLARITY_ID && window.clarity !== 'undefined') {
        try {
          //@ts-ignore
          window.clarity('set', 'user_id', undefined)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }

      return {
        ...state,
        login: null,
        status: {
          loading: false,
          success: true,
          error: null,
        },
        currentMenu: 'guest',
        social_connect_error: null,
      }
    }
    case GET_ME: {
      return state
    }
    case GET_ME_SUCCESS: {
      const user = action.payload

      try {
        setUser({
          email: user.email,
          id: user.id ? user.id.toString() : undefined,
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      //id is not a sensitive data so its ok to save it
      //@ts-ignore
      if (MICROSOFT_CLARITY_ID && window.clarity !== 'undefined') {
        try {
          //@ts-ignore
          window.clarity('set', 'user_id', user.id.toString())
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      }

      return {
        ...state,
        login: {
          ...state.login,
          user,
        },
      }
    }
    case GET_ME_FAIL: {
      return {
        ...state,
        currentMenu: 'guest', //reset
        login: null,
      }
    }
    case SWITCH_MENU: {
      return {
        ...state,
        currentMenu: action.payload,
      }
    }
    case SET_SOCIAL_CONNECT_ERROR: {
      return {
        ...state,
        social_connect_error: action.payload,
      }
    }
    default:
      return state
  }
}

export default userReducer
