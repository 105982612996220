import {createContext, useContext, useEffect, useState} from 'react'
import {useIsAdmin, useIsLoggedIn} from 'src/hooks/user'
import {getCollaboratorPermissionAndSettingsApi} from 'src/services/api/user'
import {CollaboratorPermissionAndSettingsType} from 'src/types/permissionAndAccess'

interface PermissionContextProps {
  permission: CollaboratorPermissionAndSettingsType | null
  can: (
    permission: CollaboratorPermissionAndSettingsType['permission'],
  ) => boolean
}

export const PermissionContext = createContext<
  PermissionContextProps | undefined
>(undefined)

PermissionContext.displayName = 'PermissionContext'

export const usePermissionContext = () => {
  const context = useContext(PermissionContext)
  if (context === undefined)
    throw new Error(
      `${PermissionContext.displayName} must be used within provider`,
    )

  return context
}

export default function PermissionProvider(props: {
  children: React.ReactChild
}) {
  const [permission, setPermission] =
    useState<CollaboratorPermissionAndSettingsType | null>(null)

  const isLoggedIn = useIsLoggedIn()
  const isAdmin = useIsAdmin()

  const can = (user: CollaboratorPermissionAndSettingsType['permission']) => {
    if (!permission) {
      // if null it means not registered as a collaborator
      return true
    }

    return permission['permission'] === user
  }

  useEffect(() => {
    if (!isLoggedIn || isAdmin) {
      setPermission(null)
      return
    }

    // get collaborator permission
    getCollaboratorPermissionAndSettingsApi().then(setPermission)
  }, [isAdmin, isLoggedIn])

  return (
    <PermissionContext.Provider
      value={{
        permission,
        can,
      }}
    >
      {props.children}
    </PermissionContext.Provider>
  )
}
