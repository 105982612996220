import {SvgIconProps} from '@material-ui/core/SvgIcon'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

const ArrowDownIcon = (props: SvgIconProps) => {
  const {style, ...otherProps} = props
  return (
    <KeyboardArrowDown
      fontSize="inherit"
      style={{fontSize: 14, ...style}}
      {...otherProps}
    />
  )
}

export default ArrowDownIcon
