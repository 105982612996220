import ForgotPasswordDialog from 'src/components/modules/dialog/forgotPassword/ForgotPassword'
import OrtOnboardingDialog from 'src/components/modules/dialog/ortOnboarding/OrtOnboarding'
import QuestionDialog from 'src/components/modules/dialog/question/Question'
import LanguagesDialog from 'src/components/modules/dialog/languages/Languages'
import SigninDialog from 'src/components/modules/dialog/Signin'
import SignupDialog from 'src/components/modules/dialog/signup/Signup'
import ResetPasswordDialog from 'src/components/modules/dialog/resetPassword/ResetPassword'
import EmailVerifiedDialog from 'src/components/modules/dialog/EmailVerified'
import {ModalType} from 'src/types/modal'
import ContactUsModal from 'src/components/modules/contactUs/ContactUsModal'

export default function ActiveModal(props: {
  modal: ModalType
  setModal: React.Dispatch<React.SetStateAction<ModalType>>
}) {
  const {modal, setModal} = props

  const handleCloseModal = () => setModal('none')

  if (modal === 'contact-us') {
    return <ContactUsModal open onClose={handleCloseModal} />
  }

  if (modal === 'forgot-password') {
    return <ForgotPasswordDialog open onClose={handleCloseModal} />
  }

  if (modal === 'reset-password') {
    return <ResetPasswordDialog open onClose={handleCloseModal} />
  }

  if (modal === 'signin') {
    return <SigninDialog open onClose={handleCloseModal} setModal={setModal} />
  }

  if (modal === 'question') {
    return (
      <QuestionDialog open onClose={handleCloseModal} setModal={setModal} />
    )
  }

  if (modal === 'signup') {
    return <SignupDialog open onClose={handleCloseModal} setModal={setModal} />
  }

  if (modal === 'languages') {
    return <LanguagesDialog open onClose={handleCloseModal} />
  }

  if (modal === 'email-verified') {
    return <EmailVerifiedDialog open onClose={handleCloseModal} />
  }

  if (modal === 'ort-onboarding') {
    return <OrtOnboardingDialog open onClose={handleCloseModal} />
  }

  return null
}
