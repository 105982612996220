import {
  CURRENCY_SYMBOL_CAD,
  CURRENCY_SYMBOL_USD,
  CURRENCY_TYPE_CAD,
  CURRENCY_TYPE_USD,
} from 'src/constants/currency'
import {CurrencyType} from 'src/types/listing'

export function getValueFromLabel(label: string) {
  return label.toString().toLowerCase().replace(/ /g, '_')
}

export function isValidEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function openInNewTab(href: string) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: href,
  }).click()
}

export function ucFirst(str: string) {
  if (!str) {
    return str
  }

  if (str.length === 1) {
    return str.charAt(0).toUpperCase()
  }

  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

export function formatNumber(
  number: number | null | undefined,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
) {
  if (number === 0) {
    return 0
  }

  if (!number) {
    return ''
  }

  return number.toLocaleString('en-US', {
    maximumFractionDigits,
    minimumFractionDigits,
  })
}

export function formatCurrency(number: number, currency: CurrencyType) {
  if (!number || !currency) {
    return ''
  }

  return new Intl.NumberFormat(`en-US`, {
    currency,
    style: 'currency',
  }).format(number)
}

export function getCurrencySymbol(currency?: CurrencyType) {
  if (currency === CURRENCY_TYPE_CAD) {
    return CURRENCY_SYMBOL_CAD
  }

  if (currency === CURRENCY_TYPE_USD) {
    return CURRENCY_SYMBOL_USD
  }

  return CURRENCY_SYMBOL_CAD
}

export function sleep(n: number) {
  return new Promise((resolve) => setTimeout(resolve, n))
}

export function isNumber(value: any) {
  const regex = /^[0-9]+$/
  return regex.test(value)
}

export function isElementInViewport(el: Element | null | undefined) {
  if (typeof window === 'undefined' || !el) return false //on ssr

  let rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= window.innerHeight &&
    rect.right <= window.innerWidth
  )
}

export const parallel = async (...args: Promise<any>[]) => {
  return await Promise.all(args)
}

export const parallelArr = async (args: Promise<any>[]) => {
  return await Promise.all(args)
}

export const tryAsyncFunc = async <T>(
  func: Promise<T>,
): Promise<[T | null, any | null]> => {
  try {
    const res = await func
    if (typeof res === 'undefined') {
      // eslint-disable-next-line no-console
      console.error(new Error('No return value from func'))
    }

    return [res, null]
  } catch (err) {
    return [null, err]
  }
}
