import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles(
  ({breakpoints, palette, spacing}) => ({
    flexWrapper: {
      display: 'flex',
      gap: spacing(2),
      flexDirection: 'column',
      marginBottom: spacing(2),
    },
    nameWrapper: {
      display: 'flex',
      marginBottom: spacing(2),
      gap: spacing(2),
      [breakpoints.down(552)]: {
        flexDirection: 'column',
        gap: 0,
      },
    },
    socialButton: {
      border: `1px solid ${palette.divider}`,
      color: `${palette.action.active} !important`,
    },
    App_button: {
      width: '100%',
      maxWidth: '229px',
      [breakpoints.down(900)]: {
        width: '100% !important',
        maxWidth: '100% !important',
        marginTop: '15px !important',
      },
      [breakpoints.down(1128)]: {
        width: '100% !important',
        maxWidth: '100% !important',
        marginTop: '15px !important',
      },
    },
    email: {
      color: palette.primary.main,
    },
    arrowBack: {
      cursor: 'pointer',
      fontSize: 17.15,
    },
  }),
  {index: 1},
)
