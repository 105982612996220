import {useRouter} from 'next/router'
import {useTranslation} from 'next-i18next'
import AppModal from 'src/components/elements/AppModal'
import Form from './Form'

export default function ResetPasswordDialog(props: {
  open: boolean
  onClose: () => void
}) {
  const {open, onClose} = props

  const router = useRouter()
  const {t} = useTranslation('auth')

  const handleClose = () => {
    let newQuery = {
      ...router.query,
    }
    newQuery.token = ''
    router.replace({pathname: router.pathname, query: newQuery})
    onClose()
  }

  return (
    <AppModal
      size="small"
      title={t('Create new password')}
      open={open}
      onClose={handleClose}
    >
      <Form />
    </AppModal>
  )
}
