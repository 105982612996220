import React, {Fragment, useEffect, useRef} from 'react'
import AppTypography from 'src/components/elements/typography/AppTypography'
import CloseIcon from 'src/components/elements/icons/Close'
import AppDivider from 'src/components/elements/AppDivider'
import clsx from 'clsx'
import AppPortal from 'src/components/helpers/AppPortal'
import {useComponentId} from 'src/utils/dom'

function AppModal(props: {
  title?: string
  open: boolean
  onClose?: () => void
  size?: 'small' | 'normal' | 'big' | 'bigger' | 'full-screen'
  children?: React.ReactNode
  titleLeft?: React.ReactNode
  stickyTitle?: boolean
}) {
  const {
    title,
    children,
    titleLeft,
    open,
    onClose,
    size = 'normal',
    stickyTitle = true, //default
  } = props
  const modalRef = useRef<HTMLDivElement>(null)
  const componentId = useComponentId()

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose()
      }
    }

    if (open) {
      document.addEventListener('keydown', handleEscape)
      document.body.style.overflow = 'hidden'
      modalRef.current?.focus()
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
      document.body.style.overflow = 'unset'
    }
  }, [open, onClose])

  if (!open) return null

  return (
    <AppPortal>
      <div
        className="fixed inset-0 z-modal flex items-center justify-center mx-8 md:mx-0"
        role="dialog"
        aria-modal="true"
        ref={modalRef}
        aria-labelledby={`modal-title-${componentId}`}
      >
        <div
          className="fixed z-under-modal inset-0 bg-black/30 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        />

        <div
          className={clsx(
            'bg-white shadow-xl overflow-hidden flex flex-col w-full max-w-full z-modal relative transition-all duration-300 ease-out transform',
            size === 'small' ? 'max-w-screen-sm' : '',
            size === 'normal' ? 'max-w-screen-md' : '',
            size === 'big' ? 'max-w-screen-lg' : '',
            size === 'bigger' ? 'max-w-screen-xl' : '',
            size === 'full-screen' ? 'max-w-none w-full h-full' : '',
            size !== 'full-screen' ? 'max-h-[80%] rounded-xl' : '',
            open ? 'opacity-100 scale-100' : 'opacity-0 scale-95',
          )}
        >
          <div
            className={clsx(
              'w-full flex-1 overflow-y-auto h-full relative',
              size === 'small' ? 'pb-8 px-8 ' : '',
              size === 'normal' ? 'pb-8 px-8' : '',
              size === 'big' ? 'pb-10 px-10' : '',
              size === 'bigger' ? 'pb-12 px-12' : '',
              size === 'full-screen' ? 'pb-12 px-12' : '',
            )}
          >
            <div
              className={clsx(
                stickyTitle
                  ? 'bg-white sticky top-0 left-0 right-0 z-above-modal'
                  : '',
                size === 'small' ? 'pt-8 ' : '',
                size === 'normal' ? 'pt-8' : '',
                size === 'big' ? 'pt-10' : '',
                size === 'bigger' ? 'pt-12' : '',
                size === 'full-screen' ? 'pt-12' : '',
              )}
            >
              <div className="flex flex-row justify-between items-center gap-2">
                {titleLeft && <Fragment>{titleLeft}</Fragment>}
                {title ? (
                  <AppTypography
                    id={`modal-title-${componentId}`}
                    variant="displaySmall"
                    neutralColor={900}
                  >
                    {title}
                  </AppTypography>
                ) : (
                  <div />
                )}

                {onClose && (
                  <button
                    type={'button'}
                    onClick={onClose}
                    className="p-2 hover:bg-neutral-100 rounded-full transition-colors"
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
              <AppDivider className="my-5" />
            </div>
            <div className="w-full">{children}</div>
          </div>
        </div>
      </div>
    </AppPortal>
  )
}

export default AppModal
