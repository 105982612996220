import {useEffect, useState} from 'react'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import TextareaAutosize, {
  TextareaAutosizeProps,
} from '@material-ui/core/TextareaAutosize'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {useStyles} from './styles'

interface Props extends TextareaAutosizeProps {
  description?: React.ReactNode
  label?: string
  optional?: boolean
  maxCount?: number
  withoutLimit?: boolean
  expandable?: boolean
}

const AppTextArea = (props: Props) => {
  const {
    className,
    description,
    label,
    optional,
    maxCount = 300,
    value,
    withoutLimit,
    expandable,
    name,
    ...otherProps
  } = props
  const classes = useStyles()

  const textAreaProps: TextareaAutosizeProps = {
    'aria-invalid': false,
    className: clsx(className, classes.textarea),
    minRows: 5,
  }

  const [currentCount, setCurrentCount] = useState(0)
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleExpand = () => setExpanded(!expanded)
  const [textAreaPropsToSet, setTextAreaPropsToSet] =
    useState<TextareaAutosizeProps>(textAreaProps)

  useEffect(() => {
    if (expanded) {
      setTextAreaPropsToSet({...textAreaProps})
      return
    }

    setTextAreaPropsToSet({...textAreaProps, maxRows: 5})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  useEffect(() => {
    if (typeof value === 'string') {
      setCurrentCount(value?.length)
    }
  }, [value])

  return (
    <div className={classes.flexWrapper}>
      <div className="flex flex-row justify-start items-start gap-1">
        <AppTypography
          variant="action"
          neutralColor={800}
          component="label"
          htmlFor={name}
        >
          {label}
        </AppTypography>
        {optional ? null : (
          <AppTypography
            component="span"
            className="text-primary-main"
            variant="body"
          >
            *
          </AppTypography>
        )}
      </div>
      <div className={classes.flexWrapper}>
        {withoutLimit ? (
          <TextareaAutosize
            {...textAreaPropsToSet}
            value={value}
            {...otherProps}
          />
        ) : (
          <TextareaAutosize
            {...textAreaPropsToSet}
            value={value}
            {...otherProps}
            maxLength={maxCount}
          />
        )}
        {!withoutLimit && (
          <AppTypography
            className={classes.count}
            neutralColor={400}
            variant="caption"
          >
            {currentCount}/{maxCount}
          </AppTypography>
        )}
        <Expandable
          expandable={expandable}
          expanded={expanded}
          toggleExpand={toggleExpand}
        />
      </div>
      {description}
    </div>
  )
}

export default AppTextArea

function Expandable(props: {
  expandable?: boolean
  expanded: boolean
  toggleExpand: () => void
}) {
  const {expandable, expanded, toggleExpand} = props

  if (!expandable) {
    return null
  }

  return (
    <div className="absolute bottom-1 right-1">
      <IconButton onClick={toggleExpand}>
        {expanded ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )}
      </IconButton>
    </div>
  )
}
