import {useTranslation} from 'next-i18next'
import clsx from 'clsx'
import {ButtonProps} from '@material-ui/core/Button'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import ArrowLeftIcon from 'src/components/elements/icons/ArrowLeft'
export default function ActionButtonsGroup(props: {
  noPadding?: boolean
  containerProps?: {className?: string}
  primaryBtnProps?: ButtonProps
  secondaryBtnProps?: ButtonProps
}) {
  const {
    primaryBtnProps,
    secondaryBtnProps,
    containerProps,
    noPadding = false,
  } = props

  const {t} = useTranslation('common')

  //fix disabled false being disabled...
  if (primaryBtnProps && !primaryBtnProps.disabled) {
    delete primaryBtnProps.disabled
  }
  if (secondaryBtnProps && !secondaryBtnProps.disabled) {
    delete secondaryBtnProps.disabled
  }

  return (
    <div
      {...containerProps}
      className={clsx(
        containerProps?.className,
        noPadding ? '' : 'py-4 md:py-6',
        'grid grid-cols-1 sm:grid-cols-2 gap-2',
      )}
    >
      <AppRoundedButton
        children={t('back')}
        startIcon={<ArrowLeftIcon />}
        variant="outlined"
        {...secondaryBtnProps}
        className={secondaryBtnProps?.className}
      />
      <AppRoundedButton
        children={t('next')}
        color="primary"
        variant="contained"
        {...primaryBtnProps}
        className={primaryBtnProps?.className}
      />
    </div>
  )
}
