import clsx from 'clsx'
import classes from './styles.module.scss'
import Button, {ButtonProps} from '@material-ui/core/Button'

export default function AppRoundedButton(props: ButtonProps) {
  const className = clsx(
    props.className,
    classes.AppRoundedButton,
    props.size === 'small' ? classes.small : '',
  )

  return <Button disableElevation {...props} className={className} />
}
