import {Fragment, useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import {
  Controller,
  useForm,
  UseFormReturn,
  UseFormRegister,
} from 'react-hook-form'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppCheckBox from 'src/components/forms/CheckBox'
import AppDivider from 'src/components/elements/AppDivider'
import AppSelect from 'src/components/forms/Select'
import AppTextField from 'src/components/forms/textField/AppTextField'
import AppTypography from 'src/components/elements/typography/AppTypography'
import SocialLogin from 'src/components/modules/dialog/SocialLogin'
import Hidden from 'src/components/helpers/Hidden'
import {
  publicCheckIfEmailIsAvailableApi,
  publicRegisterUserApi,
} from 'src/services/api/auth'
import {
  publicResendEmailVerificationApi,
  publicVerifyEmailApi,
} from 'src/services/api/user'
import {ValidationError, fieldError} from 'src/hooks/form'
import {registerResolver} from 'src/services/validation/user'
import {months} from 'src/utils/data'
import {stepOneFields, useStepTwoFields} from './data'
import {useStyles} from './styles'
import {useCurrentLocale} from 'src/hooks/locale'
import {
  LANGUAGE_EN,
  LANGUAGE_FR,
  LOCALE_EN,
  LOCALE_FR,
} from 'src/constants/locale'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import {ModalType} from 'src/types/modal'
import axios, {CancelTokenSource} from 'axios'
import {LOGIN_SUCCESS} from 'src/store/actionTypes'
import {useDispatch} from 'react-redux'
import {useAppContext} from 'src/context/AppProvider'
import {dayjs} from 'src/utils/date'
import Visible from 'src/components/helpers/Visible'
import {checkIsPasswordValid} from 'src/utils/password'
import * as gtm from 'src/lib/gtm'
import {publicAddDataCollectionApi} from 'src/services/api/dataCollection'
import {RegisterFormValues} from 'src/types/form'
import AppModal from 'src/components/elements/AppModal'

const initialValues: RegisterFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
  day: '',
  year: '',
  month: '',
  birthdate: '',
  language: LANGUAGE_FR,
  accept_terms: false,
  // accept_newsletters: true,
}

export default function SignupDialog(props: {
  open: boolean
  onClose: () => void
  setModal: React.Dispatch<React.SetStateAction<ModalType>>
}) {
  const {open, onClose, setModal} = props
  const {t} = useTranslation(['auth', 'common'])

  const [warning, setWarning] = useState<boolean>(false)
  const [serverError, setServerError] = useState<ValidationError<any>>(null)
  const [serverErrorForPassword, setServerErrorForPassword] =
    useState<ValidationError<any>>(null)
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState<boolean>(false)
  const emailRef = useRef<any>(null)
  const passwordRef = useRef<any>(null)
  const currentLocale = useCurrentLocale()
  const classes = useStyles()
  const checkEmailCancelSource = useRef<CancelTokenSource | null>(null)
  const checkPasswordCancelSource = useRef<CancelTokenSource | null>(null)

  //autoselect current lang
  initialValues.language =
    currentLocale === LOCALE_FR ? LANGUAGE_FR : LANGUAGE_EN

  const {
    control,
    formState: {errors},
    getValues,
    handleSubmit,
    reset,
    register,
    watch,
    clearErrors,
  } = useForm<RegisterFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      ...initialValues,
    },
    resolver: registerResolver,
  })

  const email = watch('email')
  const password = watch('password')

  useEffect(() => {
    emailRef.current = setTimeout(() => {
      checkEmail()
    }, 600)
    return () => clearTimeout(emailRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  useEffect(() => {
    passwordRef.current = setTimeout(() => {
      checkIsPasswordValid(
        password,
        checkPasswordCancelSource,
        setServerErrorForPassword,
      )
    }, 600)
    return () => clearTimeout(passwordRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password])

  useEffect(() => {
    if (step === 1) {
      checkEmail()
      checkIsPasswordValid(
        password,
        checkPasswordCancelSource,
        setServerErrorForPassword,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  useEffect(() => {
    checkEmail()
    checkIsPasswordValid(
      password,
      checkPasswordCancelSource,
      setServerErrorForPassword,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkEmail = () => {
    if (!email || !email.includes('@')) {
      setServerError(null)
      return
    }
    if (checkEmailCancelSource.current) {
      checkEmailCancelSource.current.cancel()
    }

    checkEmailCancelSource.current = axios.CancelToken.source()
    publicCheckIfEmailIsAvailableApi(
      email,
      checkEmailCancelSource.current?.token,
    )
      .then(() => {
        setServerError(null)
      })
      .catch((ex) => {
        if (axios.isCancel(ex)) {
          setServerError(null)
          return
        }

        if (!ex || !ex.response || !ex.response.data) {
          setServerError(null)
          return
        }
        setServerError(ex.response.data)
      })
  }

  const emailError = fieldError('email', {
    form: errors,
    response: serverError,
  })
  const passwordError = fieldError('password', {
    form: errors,
    response: serverErrorForPassword,
  })
  const languageError = fieldError('language', {
    form: errors,
    response: serverError,
  })

  const acceptTermsError = fieldError('accept_terms', {
    form: errors,
    response: serverError,
  })

  const handleClose = useCallback(() => {
    setStep(1)
    setWarning(false)
    setServerError(null)
    setServerErrorForPassword(null)
    reset({...initialValues}, {keepErrors: false, keepValues: false})
    onClose()
  }, [onClose, reset])

  const handleContinueToSecondStep = useCallback(() => {
    if (serverError || serverErrorForPassword) {
      return
    }

    const keys: (keyof RegisterFormValues)[] = [
      'email',
      'confirm_password',
      'first_name',
      'last_name',
      'password',
    ]

    const values = getValues()
    if (values.password !== values.confirm_password) {
      setWarning(true)
      return
    }

    const hasEmptyFields = keys.some((key) => !values[key])
    if (hasEmptyFields) {
      setWarning(true)
      return
    }

    setWarning(false)
    setStep(2)
  }, [serverError, serverErrorForPassword, getValues])

  const handleContinueToFirstStep = useCallback(() => {
    clearErrors()
    setServerError(null)
    setServerErrorForPassword(null)

    const keys: (keyof RegisterFormValues)[] = [
      'email',
      'confirm_password',
      'first_name',
      'last_name',
      'password',
    ]

    const values = getValues()
    const hasEmptyFields = keys.some((key) => !values[key])
    if (hasEmptyFields) {
      setWarning(true)
      return
    }

    setWarning(false)
    setStep(1)
  }, [clearErrors, getValues])

  const onRegisterUser = (values: RegisterFormValues) => {
    setServerError(null)
    setServerErrorForPassword(null)
    const monthIndex = months.indexOf(values.month)

    //make sure we get the latest date
    values.birthdate = dayjs(
      new Date(parseInt(values.year), monthIndex, parseInt(values.day)),
    ).format('YYYY-MM-DD')

    setLoading(true)
    publicRegisterUserApi(values)
      .then((data) => {
        gtm.userSignUp(data)
        publicAddDataCollectionApi('signup')
        setStep(3)
      })
      .catch((ex) => {
        setServerError(ex.response.data)
      })
      .finally(() => setLoading(false))
  }

  return (
    <AppModal
      size="small"
      open={open}
      onClose={handleClose}
      title={step === 3 ? t('Verify your account') : t('create_your_account')}
      titleLeft={
        <Visible when={step === 1}>
          <IconButton onClick={() => setModal('question')}>
            <ArrowBack className={classes.arrowBack} />
          </IconButton>
        </Visible>
      }
    >
      <Hidden when={!warning}>
        <Alert severity="warning" className="mb-6">
          {t('please_fill_the_required_fields')}
        </Alert>
      </Hidden>
      <form className="w-full" onSubmit={handleSubmit(onRegisterUser)}>
        <FirstStep
          step={step}
          register={register}
          errors={errors}
          emailError={emailError}
          passwordError={passwordError}
          handleNext={handleContinueToSecondStep}
        />
        <SecondStep
          step={step}
          control={control}
          watch={watch}
          emailError={emailError}
          languageError={languageError}
          acceptTermsError={acceptTermsError}
          isLoading={loading}
          handleBack={handleContinueToFirstStep}
        />
        <ThirdStep step={step} email={email} handleClose={handleClose} />
      </form>
      <Footer step={3} />
    </AppModal>
  )
}

function FirstStep(props: {
  emailError?: string
  passwordError?: string
  step: number
  register: UseFormRegister<RegisterFormValues>
  errors: any
  handleNext: () => void
}) {
  const {step, register, errors, handleNext, emailError, passwordError} = props

  const classes = useStyles()
  const {t} = useTranslation('auth')

  if (step !== 1) {
    return null
  }

  return (
    <div>
      <Error error={emailError} />
      <div className={classes.flexWrapper}>
        {stepOneFields.slice(0, 1).map((field) => {
          return (
            <AppTextField
              key={field.label}
              {...field}
              label={t(field.label)}
              placeholder={t(field.placeholder)}
              register={register}
              error={Boolean(errors[field.name]?.message)}
              message={`${t(errors[field.name]?.message)}`}
            />
          )
        })}
      </div>
      <div className={classes.nameWrapper}>
        {stepOneFields.slice(1, 3).map((field) => {
          return (
            <AppTextField
              key={field.label}
              {...field}
              label={t(field.label)}
              placeholder={t(field.placeholder)}
              register={register}
              error={Boolean(errors[field.name]?.message)}
              message={`${t(errors[field.name]?.message)}`}
            />
          )
        })}
      </div>
      <div className={classes.flexWrapper}>
        {stepOneFields.slice(3).map((field) => {
          if (field.name === 'password') {
            return (
              <AppTextField
                key={field.label}
                {...field}
                label={t(field.label)}
                placeholder={t(field.placeholder)}
                register={register}
                error={
                  Boolean(errors[field.name]?.message) || Boolean(passwordError)
                }
                message={`${t(
                  errors[field.name]?.message ?? t(passwordError ?? ''),
                )}`}
              />
            )
          }

          return (
            <AppTextField
              key={field.label}
              {...field}
              label={t(field.label)}
              placeholder={t(field.placeholder)}
              register={register}
              error={Boolean(errors[field.name]?.message)}
              message={`${t(errors[field.name]?.message)}`}
            />
          )
        })}
      </div>
      <AppButton
        onClick={handleNext}
        variant="contained"
        color="primary"
        fullWidth
      >
        {t('continue')}
      </AppButton>
    </div>
  )
}

function SecondStep(props: {
  step: number
  emailError?: string
  languageError?: string
  acceptTermsError?: string
  control: UseFormReturn<RegisterFormValues>['control']
  watch: UseFormReturn<RegisterFormValues>['watch']
  isLoading: boolean
  handleBack: () => void
}) {
  const {
    step,
    emailError,
    languageError,
    acceptTermsError,
    control,
    watch,
    isLoading,
    handleBack,
  } = props

  const [hasAgeError, setHasAgeError] = useState<boolean>(false)

  const {t} = useTranslation(['auth', 'common'])
  const classes = useStyles()
  const stepTwoFields = useStepTwoFields()
  const currentLocale = useCurrentLocale()

  const year = watch('year')

  useEffect(() => {
    if (year) {
      const intValue = parseInt(year)
      if (intValue > new Date().getFullYear() - 18 || intValue < 1910) {
        setHasAgeError(true)
        return
      }

      setHasAgeError(false)
      return
    }

    setHasAgeError(false)
  }, [year])

  const acceptTermsUrl =
    currentLocale === LOCALE_EN
      ? '/en/terms-of-use'
      : '/conditions-d-utilisation'

  if (step !== 2) {
    return null
  }

  return (
    <>
      <Error error={emailError} />
      <div className="flex flex-row mb-1 gap-1">
        <AppTypography variant="action" neutralColor={800} component="label">
          {t('birthday', {ns: 'common'})}
        </AppTypography>
        <AppTypography
          component="span"
          variant="body"
          className="text-text-primary"
        >
          *
        </AppTypography>
      </div>
      <div className={classes.nameWrapper}>
        {stepTwoFields.slice(0, 3).map(({name, options, placeholder}) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({field: {ref, ...others}, formState: {errors}}) => (
              <AppSelect
                options={options}
                placeholder={t(placeholder, {ns: 'common'})}
                {...others}
                inputRef={ref}
                error={Boolean(errors[name]?.message)}
                message={
                  errors[name] ? t(errors[name]?.message as string) : undefined
                }
              />
            )}
          />
        ))}
      </div>
      <AgeError visible={hasAgeError} />
      <Controller
        name="language"
        control={control}
        render={({field: {ref, ...others}, formState: {errors}}) => (
          <AppSelect
            {...stepTwoFields[3]}
            withLabel
            label={t('default_language', {ns: 'common'})}
            placeholder={t(stepTwoFields[3].placeholder, {ns: 'common'})}
            error={Boolean(languageError)}
            message={languageError ? t(languageError) : undefined}
            {...others}
            inputRef={ref}
          />
        )}
      />
      <div className="mb-6">
        <Controller
          name="accept_terms"
          control={control}
          render={({field: {value, ...others}}) => (
            <AppCheckBox
              {...others}
              value={value === undefined ? false : value}
              checked={value === undefined ? false : value}
              label={
                <AppTypography
                  component="span"
                  className="pl-2"
                  variant="caption"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('accept_terms', {url: acceptTermsUrl}),
                    }}
                  />
                </AppTypography>
              }
              error={Boolean(acceptTermsError)}
              message={acceptTermsError ? t(acceptTermsError) : undefined}
              className="mt-4 block"
            />
          )}
        />
        {/*<Controller
          name="accept_newsletters"
          control={control}
          render={({field: {value, ...others}}) => (
            <AppCheckBox
              {...others}
              value={value === undefined ? false : value}
              checked={value === undefined ? false : value}
              label={
                <AppTypography
                  component="span"
                  className="pl-2"
                  variant="caption"
                >
                  {t('accept_newsletters')}
                </AppTypography>
              }
              className="mt-4 block"
            />
          )}
        /> */}
      </div>
      <AppButton
        variant="outlined"
        disabled={isLoading}
        onClick={handleBack}
        className={classes.App_button}
      >
        {isLoading ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          t('back', {ns: 'common'})
        )}
      </AppButton>
      <AppButton
        variant="contained"
        color="primary"
        className={classes.App_button}
        style={{float: 'right'}}
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          t('create_your_account')
        )}
      </AppButton>
    </>
  )
}

function ThirdStep(props: {
  step: number
  email?: string
  handleClose: () => void
}) {
  const {step, email, handleClose} = props

  const {t} = useTranslation('auth')
  const classes = useStyles()

  const [loading, setLoading] = useState<boolean>(false)
  const [tokenErr, setTokenErr] = useState<boolean>(false)
  const [token, setToken] = useState<string | null>(null)
  const dispatch = useDispatch()
  const {setModal} = useAppContext()

  const resendEmail = () => {
    if (email) {
      setLoading(true)
      publicResendEmailVerificationApi(email).finally(() => setLoading(false))
    }
  }

  const checkToken = () => {
    if (token && email) {
      setLoading(true)
      setTokenErr(false)
      publicVerifyEmailApi(email, token)
        .then((res) => {
          if (res.status === 200) {
            setModal('email-verified')
            dispatch({
              payload: res.data,
              type: LOGIN_SUCCESS,
            })
          }
        })
        .catch(() => {
          setTokenErr(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  if (step !== 3) {
    return null
  }

  return (
    <>
      <div className={classes.flexWrapper}>
        <AppTypography variant="body" neutralColor={600}>
          {t('verify_message_1', {email: email})}
        </AppTypography>
        <div className="mb-4">
          <AppTypography variant="body" neutralColor={600}>
            {`${t('verify_message_3')} `}
          </AppTypography>
          <div className="flex flex-row justify-between items-center mt-6 gap-3">
            <AppTextField
              label={t('Verification Code')}
              name="token"
              placeholder="123456"
              onChange={(e) => setToken(e.target.value)}
              value={token}
              error={tokenErr}
              message={t('error_email_token')}
            />
            <div className="mt-6">
              <AppButton
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={checkToken}
              >
                {t('check_token')}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
      <AppButton
        variant="outlined"
        disabled={loading}
        onClick={handleClose}
        className={classes.App_button}
      >
        {t('cancel')}
      </AppButton>
      <AppButton
        variant="outlined"
        color="primary"
        className={classes.App_button}
        style={{float: 'right'}}
        disabled={loading}
        onClick={resendEmail}
      >
        {t('resend_email')}
      </AppButton>
    </>
  )
}

function Footer(props: {step: number}) {
  const {t} = useTranslation('auth')

  if (props.step !== 3) {
    return null
  }

  return (
    <Fragment>
      <AppDivider className="mt-9" />
      <AppTypography
        variant="caption"
        className="text-text-secondary text-center py-1"
      >
        {t('or_continue_with')}
      </AppTypography>
      <AppDivider className="mb-9" />
      <SocialLogin />
    </Fragment>
  )
}

function Error(props: {error?: string}) {
  const {error} = props

  const {t} = useTranslation('auth')

  if (!error) {
    return null
  }

  return (
    <Alert className="mb-4" severity={'error'}>
      {t(error)}
    </Alert>
  )
}

function AgeError(props: {visible: boolean}) {
  const {t} = useTranslation('auth')

  if (!props.visible) {
    return null
  }

  return (
    <Alert className="mb-4" severity={'error'}>
      {t('age_error')}
    </Alert>
  )
}
