import {FieldError, Resolver} from 'react-hook-form'
import {
  validateEmail,
  validateName,
  validateDay,
  validateYear,
  validateMonth,
  validatePassword,
  validateLanguage,
  validateConfirmPassword,
  validateLoginPassword,
  validateAcceptTerms,
} from 'src/services/validation/validateModules'
import {LoginFormValues, RegisterFormValues} from 'src/types/form'

export const registerResolver: Resolver<RegisterFormValues> = async (
  values: RegisterFormValues,
) => {
  const errors: Record<string, FieldError> = {}
  for (const field in values) {
    const key = field as keyof typeof values

    let message: string | undefined = ''
    switch (key) {
      case 'day':
        message = validateDay(values[key])
        break
      case 'year':
        message = validateYear(values[key])
        break
      case 'email':
        message = validateEmail(values[key])
        break
      case 'first_name':
        message = validateName('First name')(values[key])
        break
      case 'last_name':
        message = validateName('Last name')(values[key])
        break
      case 'month':
        message = validateMonth(values[key])
        break
      case 'password':
        message = validatePassword(values[key])
        break
      case 'language':
        validateLanguage(values[key])
        break
      case 'confirm_password':
        message = validateConfirmPassword(values[key], values.password)
        break
      case 'accept_terms':
        message = validateAcceptTerms(values[key])
        break
      default:
        break
    }

    if (message) {
      errors[key] = {
        type: '',
        message,
      }
    }
  }

  return {
    errors,
    values: values.email
      ? {...values, email: values.email.toLowerCase()}
      : values,
  }
}

export const loginResolver: Resolver<LoginFormValues> = async (
  values: LoginFormValues,
) => {
  const errors: Record<string, FieldError> = {}
  for (const field in values) {
    const key = field as keyof typeof values
    let message: string | undefined = ''

    switch (key) {
      case 'email':
        message = validateEmail(values[key])
        break
      case 'password':
        message = validateLoginPassword(values[key])
        break
      default:
        break
    }

    if (message) {
      errors[key] = {
        type: '',
        message,
      }
    }
  }

  return {
    errors,
    values: values.email
      ? {...values, email: values.email.toLowerCase()}
      : values,
  }
}
