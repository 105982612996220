import {useSelector} from 'react-redux'
import {
  isAdminSelector,
  currentMenuSelector,
  userInfoSelector,
} from 'src/store/selectors/userSelector'
import {
  bookingBadgeSelector,
  messageBadgeSelector,
} from 'src/store/selectors/badgeSelector'
import {ConnectedUserType, UserPhoneNumberType} from 'src/types/user'

export function useUserInfo() {
  const userInfo = useSelector(userInfoSelector)

  return userInfo as ConnectedUserType
}

export function useIsLoggedIn() {
  const userInfo = useUserInfo()

  if (userInfo) {
    return true
  }

  return false
}

export function useIsAdmin() {
  const isAdmin = useSelector(isAdminSelector)

  return isAdmin
}

export function useIsHost() {
  const userInfo = useUserInfo()

  return userInfo?.is_host
}

export function useIsGuestMenu() {
  const currentMenu = useSelector(currentMenuSelector)

  return currentMenu === 'guest'
}

export function getPhoneNumber(
  user?: {user_phone_numbers: UserPhoneNumberType[]} | null,
) {
  if (!user) {
    return {
      hasPhoneNumber: false,
      primaryPhoneNumber: null,
    }
  }

  const primaryPhoneNumber = user.user_phone_numbers.find(
    (phone) => phone.is_primary,
  )

  return {
    hasPhoneNumber: Boolean(primaryPhoneNumber),
    primaryPhoneNumber,
  }
}

export function useHasNewNotification() {
  const bookingBadgeInfo = useSelector(bookingBadgeSelector)
  const badgeInfo = useSelector(messageBadgeSelector)
  if (!badgeInfo || !bookingBadgeInfo) {
    return false
  }

  const {host_badge: hostBadge, guest_badge: guestBadge} = badgeInfo
  const {host_badge: hostBookingBadge, guest_badge: guestBookingBadge} =
    bookingBadgeInfo

  return hostBadge || hostBookingBadge || guestBadge || guestBookingBadge
}
