import {AnyAction} from 'redux'
import {INITIAL_CENTER, INITIAL_ZOOM_LEVEL} from 'src/constants/googleMap'
import {
  INIT_PROPERTY_STATE,
  SET_SELECTED_PROPERTY,
  SET_SELECTED_LOCATION,
  SET_SELECTED_DATE_RANGE,
  SET_SELECTED_GUESTS,
  SET_SELECTED_AMENITIES,
  SET_SEARCH_PAGE_NO,
  SET_SORTBY_FILTER,
  SET_MORE_FILTER,
  SET_PRICE_RANGE_FILTER,
  SET_MAP_BOUNDS,
  SET_MAP_CENTER_BOUNDS,
  INIT_PROPERTY_STATE_FROM_URL,
  SET_CURRENT_MAP_ZOOM,
  SET_SEARCH_LOADING,
  SET_LAST_SEARCH_PARAMS,
  SET_SCROLL_Y,
  SET_CLICKED_PROPERTY_UID,
  SET_ERROR_CODE,
  SET_TAB_VIEW,
  INCREMENT_SEARCH_COUNT,
  SET_SEARCH_DATA,
} from 'src/store/actionTypes'
import {
  DateRangeType,
  SearchMoreFiltersType,
  LocationSearchType,
  MapBoundsType,
  PriceRangeType,
  SearchPropertiesParamsType,
  SortByFilterType,
} from 'src/types/search'
import {SearchPropertyListType, SearchPropertyType} from 'src/types/property'
import {GuestSelectType} from 'src/types/guestSelect'

export type PropertySearchStoreState = {
  tabView: 'list' | 'map'
  errorCode: string | null
  loading: boolean
  clickedPropertyUid: string | null
  scrollY: number
  searchCount: number
  lastSearchParams: SearchPropertiesParamsType | null
  searchData: SearchPropertyListType
  selectedProperty: SearchPropertyType | null
  selectedLocation: LocationSearchType
  selectedDateRange: DateRangeType
  selectedGuests: GuestSelectType
  selectedAmenities: string[]
  selectedSearchPageNo: number
  sortByFilter: SortByFilterType
  moreFilters: SearchMoreFiltersType
  zoom: number
  priceRangeFilter: PriceRangeType
  mapBounds: MapBoundsType | null
  mapCenterBounds: {lat: number; lng: number}
}

const initialState: PropertySearchStoreState = {
  tabView: 'map',
  errorCode: null,
  loading: false,
  scrollY: 0,
  searchCount: 0,
  lastSearchParams: null,
  clickedPropertyUid: null,
  searchData: {
    items: [],
    total: 0,
    current_page: 0,
  },
  selectedProperty: null,
  selectedLocation: {
    address: null,
    automatic: false,
    autocompleteLocation: null,
    addressComps: {
      country: null,
      state: null,
      city: null,
      mrc: null,
      county: null,
    },
    coordinates: null,
  },
  selectedDateRange: {startDate: null, endDate: null},
  selectedGuests: {adults: 0, children: 0, infants: 0, animals: 0},
  selectedAmenities: [],
  selectedSearchPageNo: 1,
  sortByFilter: 'distance_low_to_high',
  moreFilters: {
    capacity: 0,
    rooms: 0,
    beds: 0,
    toilets: 0,
    internetType: '',
  },
  zoom: INITIAL_ZOOM_LEVEL,
  priceRangeFilter: {minPrice: 0, maxPrice: 0},
  mapBounds: null,
  mapCenterBounds: {...INITIAL_CENTER},
}

const propertySearchReducer = (
  state = initialState,
  action: AnyAction,
): PropertySearchStoreState => {
  switch (action.type) {
    case SET_SEARCH_LOADING: {
      return {...state, loading: action.payload}
    }

    case INCREMENT_SEARCH_COUNT: {
      return {...state, searchCount: state.searchCount + 1}
    }

    case SET_LAST_SEARCH_PARAMS: {
      return {...state, lastSearchParams: action.payload}
    }
    case SET_SCROLL_Y: {
      return {...state, scrollY: action.payload}
    }

    case SET_CLICKED_PROPERTY_UID: {
      return {...state, clickedPropertyUid: action.payload}
    }

    case SET_ERROR_CODE: {
      return {...state, errorCode: action.payload}
    }

    case SET_TAB_VIEW: {
      return {...state, tabView: action.payload}
    }

    case SET_SEARCH_DATA: {
      return {...state, searchData: action.payload}
    }

    case SET_SELECTED_PROPERTY: {
      return {...state, selectedProperty: action.payload}
    }

    case SET_SELECTED_LOCATION: {
      return {...state, selectedLocation: action.payload}
    }

    case SET_SELECTED_DATE_RANGE: {
      return {...state, selectedDateRange: action.payload}
    }

    case SET_SELECTED_GUESTS: {
      return {...state, selectedGuests: action.payload}
    }
    case SET_CURRENT_MAP_ZOOM: {
      return {...state, zoom: action.payload}
    }

    case SET_SELECTED_AMENITIES: {
      return {...state, selectedAmenities: action.payload}
    }

    case SET_SEARCH_PAGE_NO: {
      return {...state, selectedSearchPageNo: action.payload}
    }

    case SET_SORTBY_FILTER: {
      return {...state, sortByFilter: action.payload}
    }
    case SET_MORE_FILTER: {
      return {...state, moreFilters: action.payload}
    }

    case SET_PRICE_RANGE_FILTER: {
      return {...state, priceRangeFilter: action.payload}
    }

    case SET_MAP_BOUNDS: {
      return {...state, mapBounds: action.payload}
    }

    case SET_MAP_CENTER_BOUNDS: {
      return {...state, mapCenterBounds: action.payload}
    }

    case INIT_PROPERTY_STATE: {
      return {...initialState}
    }

    case INIT_PROPERTY_STATE_FROM_URL: {
      return {...initialState, ...action.payload}
    }

    default:
      return state
  }
}

export default propertySearchReducer
