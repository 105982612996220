import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Checkbox, {CheckboxProps} from '@material-ui/core/Checkbox'
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import CheckBoxIcon from 'src/components/elements/icons/CheckBox'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(
  ({spacing}) => ({
    root: {
      margin: 0,
      gap: spacing(2),
      '&  .MuiButtonBase-root': {
        padding: 0,
      },
    },
  }),
  {index: 1},
)

interface Props extends Omit<FormControlLabelProps, 'control'> {
  checkBoxProps?: CheckboxProps
  error?: boolean
  message?: string | null
}

const AppCheckBox = (props: Props) => {
  const {checkBoxProps, error, message, className, ...otherProps} = props
  const classes = useStyles()
  return (
    <FormControl>
      <FormControlLabel
        {...otherProps}
        classes={{
          root: clsx(classes.root, className),
        }}
        control={
          <Checkbox
            {...checkBoxProps}
            icon={<CheckBoxIcon />}
            checkedIcon={<CheckBoxIcon checked />}
          />
        }
      />
      <Error visible={Boolean(error && message)} message={message} />
    </FormControl>
  )
}

export default AppCheckBox

function Error(props: {visible: boolean; message?: string | null}) {
  const {visible, message} = props
  if (!visible) {
    return null
  }

  return <FormHelperText error>{message}</FormHelperText>
}
