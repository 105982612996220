import AppModal from 'src/components/elements/AppModal'
import ContactUsForm from 'src/components/modules/contactUs/ContactUsForm'
import {useTranslation} from 'next-i18next'
import AppTypography from 'src/components/elements/typography/AppTypography'

export default function ContactUsModal(props: {
  open: boolean
  onClose: () => void
}) {
  const {open, onClose} = props
  const {t} = useTranslation('common')

  return (
    <AppModal
      title={t('contact_us_modal_title')}
      open={open}
      onClose={onClose}
      size="big"
    >
      <div className="w-full">
        <AppTypography variant="body">
          {t('contact_us_modal_description')}
        </AppTypography>
        <ContactUsForm version="modal" />
      </div>
    </AppModal>
  )
}
