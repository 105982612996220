import {useTranslation} from 'next-i18next'
import AppModal from 'src/components/elements/AppModal'
import Form from './Form'

export default function ForgotPassword(props: {
  open: boolean
  onClose: () => void
}) {
  const {t} = useTranslation('auth')

  const {open, onClose} = props

  return (
    <AppModal
      size="small"
      title={t('forgot_password')}
      open={open}
      onClose={onClose}
    >
      <Form />
    </AppModal>
  )
}
