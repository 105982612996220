import AppModal from 'src/components/elements/AppModal'
import FormStep1 from './FormStep1'
import FormStep2 from './FormStep2'
import FormStep3 from './FormStep3'
import {useEffect, useMemo, useState} from 'react'
import {
  ADMIN_GUEST_BOOKINGS_PAGE,
  ADMIN_GUEST_MESSAGES_PAGE,
  HOME_PAGE,
} from 'src/constants/route'
import {useRouter} from 'next/router'
import {publicCompletedUserOnboardingTokenApi} from 'src/services/api/auth'
import {logoutAction} from 'src/store/actions/userAction'
import {useDispatch} from 'react-redux'
import {useUserInfo} from 'src/hooks/user'
import CircularProgress from '@material-ui/core/CircularProgress'
import {BookingListType, SearchBookingType} from 'src/types/booking'
import {getMyBookingsApi} from 'src/services/api/booking'
import {LOGIN_SUCCESS} from 'src/store/actionTypes'
import * as Sentry from '@sentry/nextjs'
import {useTranslation} from 'next-i18next'
import {signOut} from 'next-auth/client'

export default function OrtOnboarding(props: {
  open: boolean
  onClose: () => void
}) {
  const {t} = useTranslation('auth')

  const {open, onClose} = props
  const router = useRouter()
  const dispatch = useDispatch()
  const userInfo = useUserInfo()
  const [loading, setLoading] = useState<boolean>(false)
  const [bookings, setBookings] = useState<BookingListType | null>(null)
  const baseCurrentStep =
    userInfo && userInfo.onboarding_step
      ? [1, 2, 3].includes(userInfo.onboarding_step)
        ? userInfo.onboarding_step
        : 1
      : 1
  const [currentStep, setCurrentStep] = useState<1 | 2 | 3>(baseCurrentStep)

  let token = String(router.query.onboarding_token)
  if (userInfo) {
    if (userInfo.onboarding_token) {
      token = userInfo.onboarding_token
    }
  }

  useEffect(() => {
    if (loading) return

    if (userInfo) {
      setLoading(true)

      //try to finish it
      if (userInfo.onboarding_step === null) {
        processFinalStep()
        return
      }

      if ([1, 2, 3].includes(userInfo.onboarding_step)) {
        setCurrentStep(userInfo.onboarding_step)
      }

      getMyBookingsApi({
        limit: 1,
      })
        .then((res) => {
          setBookings(res)
        })
        .finally(() => setLoading(false))
    } else {
      setBookings(null)
      setCurrentStep(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  const closeModalByUser = async () => {
    //make sure to wait for that...
    await router.replace(HOME_PAGE, undefined, {shallow: true})
    dispatch(logoutAction())
    signOut({redirect: false})
    onClose()
  }

  const firstBooking: SearchBookingType | null =
    bookings && bookings?.data.length > 0 ? bookings?.data[0] : null

  const processFinalStep = async () => {
    if (loading) return
    setLoading(true)

    try {
      let res = await publicCompletedUserOnboardingTokenApi(token)
      dispatch({
        payload: res.data,
        type: LOGIN_SUCCESS,
      })
    } catch (e) {
      Sentry.captureException(e)
      // window.location.reload() //restart
    }

    onClose()
    setLoading(false)
    //go to bookings
    if (firstBooking) {
      router.push(
        `${ADMIN_GUEST_MESSAGES_PAGE}?booking_uid=${firstBooking.uid}`,
      )
    } else {
      router.push(ADMIN_GUEST_BOOKINGS_PAGE)
    }
  }

  const onCompleted = async () => {
    if (currentStep === 3) {
      await processFinalStep()
      return
    }
    setCurrentStep((prev) => {
      if (prev === 1) {
        return 2
      } else if (prev === 2) {
        return 3
      }
      return 1
    })
  }

  const title = useMemo(() => {
    if (currentStep === 2) {
      return t('onboarding_step_2')
    } else if (currentStep === 3) {
      return t('onboarding_step_3')
    }
    return t('onboarding_step_1')
  }, [t, currentStep])

  return (
    <AppModal title={title} open={open} onClose={closeModalByUser}>
      <>
        {loading && <CircularProgress />}

        {!loading && (
          <div>
            <div className="mt-4">
              {currentStep === 1 && (
                <FormStep1 token={token} onCompleted={onCompleted} />
              )}
              {currentStep === 2 && (
                <FormStep2 token={token} onCompleted={onCompleted} />
              )}
              {currentStep === 3 && (
                <FormStep3 token={token} onCompleted={onCompleted} />
              )}
            </div>
          </div>
        )}
      </>
    </AppModal>
  )
}
