import React from 'react'
import ReactDOM from 'react-dom'
import {isClient} from 'src/utils/dom'

interface PortalProps {
  children: React.ReactNode
}

const _portalContainer = isClient ? document.getElementById('portal') : null

function AppPortal(props: PortalProps): React.ReactElement | null {
  const {children} = props
  //dont render on server side
  if (!isClient) {
    return null
  }
  if (!_portalContainer) {
    return null
  }
  //if no children, null, if we dont do this, empty children will render empty <div>
  if (!children) {
    return null
  }

  //dont create <div> container, that fuckt the code and create empty <div> in the portal container
  return ReactDOM.createPortal(children, _portalContainer) as React.ReactElement
}

export default AppPortal
