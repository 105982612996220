import {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  RefObject,
} from 'react'
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date'

export const onChangeStringHandler =
  (setter: (v: string) => void) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    setter(e.currentTarget.value)
  }

export const onChangeNumberHandler =
  <T>(setter: (val: T) => void, defaultValue: T) =>
  (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = parseFloat(e.currentTarget.value)
    if (isNaN(value)) {
      setter(defaultValue)
      return
    }

    setter(value as number as unknown as T)
  }

export const onChangeIntegerHandler =
  <T>(setter: (val: T) => void, defaultValue: T) =>
  (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const value = parseInt(e.currentTarget.value, 10)

    if (isNaN(value)) {
      setter(defaultValue)
      return
    }

    if (value < 0) {
      return
    }
    setter(value as number as unknown as T)
  }

export const onUnknownChangeHandler =
  <T>(setter: (val: T) => void) =>
  (e: ChangeEvent<{value: unknown}>) => {
    setter(e.target.value as T)
  }

export const onChangeCheckedHandler =
  (setter: (val: boolean) => void) =>
  (e: ChangeEvent<HTMLInputElement>): void => {
    setter(e.currentTarget.checked)
  }

export const handleChangeSlider =
  (handler: (newValue: any) => void) =>
  (event: any, value: number | number[]) => {
    if (Array.isArray(value)) {
      handler(value[0])
      return
    }

    handler(value)
  }

export const handleAutocomplete =
  <T>(handler: (value: T) => void) =>
  (event: React.ChangeEvent<{}>, value: T) => {
    handler(value)
  }

export const onChangeDate =
  (set: (val: string) => void) => (date: MaterialUiPickersDate) => {
    const value = date?.toISOString()
    if (!value) {
      throw new Error('Missing date')
    }

    set(value)
  }

export function Include(props: {if: boolean; children: React.ReactElement}) {
  if (!props.if) {
    return null
  }

  return props.children
}

export function useIsMounted() {
  const isMounted = useRef<boolean>(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted
}

export function useComponentId() {
  return useMemo(() => Math.random().toString(36).substring(2, 9), [])
}

export function useKeyDown(keyCode: string, onKeyDown: (e: any) => void) {
  const localKeyDown = useCallback(
    (e: any) => {
      if (e.key === keyCode) {
        onKeyDown(e)
      }
    },
    [keyCode, onKeyDown],
  )

  useEffect(() => {
    window.addEventListener('keydown', localKeyDown)
    return () => {
      window.removeEventListener('keydown', localKeyDown)
    }
  }, [localKeyDown])
}

export function useOutsideClick(ref: any, handler: () => void) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler()
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  )
}

export const stopPropagation =
  (cb?: Function | undefined) => (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (cb) {
      cb(e)
    }
  }

export function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState<boolean>(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
      ),
    [],
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect()
  }, [observer, ref])

  return isIntersecting
}

export function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition, {passive: true})
    updatePosition()
    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}

export const isClient = typeof window !== 'undefined'
