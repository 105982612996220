import {useRef, useEffect, useState, useCallback} from 'react'
import {useRouter} from 'next/router'
import {SLUG_CR, APP_SLUG, SLUG_MC} from 'src/constants/slug'
import {COMPANY_NAME_CR, COMPANY_NAME_MC} from 'src/constants/company'
import {DEFAULT_LOCALE} from 'src/constants/locale'
import {ADMIN_PAGE} from 'src/constants/route'
import {fetchBuildIdApi} from 'src/services/api/global'
import * as Sentry from '@sentry/nextjs'
import {useIsMounted} from 'src/utils/dom'
import {tryAsyncFunc} from 'src/utils/other'

export function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useAttachmentsError(attachments: File[], maxCount = 20) {
  const size = useGetAttachmentsSize(attachments)
  if (size > 50) {
    // if larger than 50MB
    return 'Attachments can not be larger than 50MB'
  }

  if (!attachments) {
    return null
  }

  if (attachments.length > maxCount) {
    return `Can not upload more than ${maxCount} files`
  }

  const files = Array.from(attachments)
  const check = files.every((file) => file.size < 10 * 1024 * 1024)

  if (!check) {
    return 'Every attachment can not be larger than 10MB'
  }

  return null
}

export function useGetAttachmentsSize(attachments: File[]) {
  if (!attachments) {
    return 0
  }

  let size = 0
  const files = Array.from(attachments)
  for (const file of files) {
    size += file.size
  }

  return size / (1024 * 1024)
}

export function useCardName(name: string) {
  const router = useRouter()
  const {locale} = router

  if (locale === DEFAULT_LOCALE) {
    return `Carte de ${name}`
  }

  return `${name}'s Card`
}

export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}

export function useRouterLocationPathname() {
  const router = useRouter()
  return router.asPath.split('?')[0]
}

export function useAuthRequiredPage() {
  const router = useRouter()
  return router.asPath.startsWith(ADMIN_PAGE)
}

export function useCompanyName() {
  if (APP_SLUG === SLUG_CR) {
    return COMPANY_NAME_CR
  }

  if (APP_SLUG === SLUG_MC) {
    return COMPANY_NAME_MC
  }

  return ''
}

export function useBuildId() {
  const router = useRouter()

  const savedBuildId = useRef<string | null>(null)

  const checkBuildId = useCallback(async () => {
    const [buildId, err] = await tryAsyncFunc(fetchBuildIdApi())

    if (err) {
      Sentry.captureException(err)
      // eslint-disable-next-line no-console
      console.error(err)
      return
    }

    //has changed
    if (savedBuildId.current && buildId !== savedBuildId.current) {
      router.reload() //reload browser if set and not the same has the new one
      return
    }

    //save new build id
    savedBuildId.current = buildId
  }, [router])

  //when we change page, check build id
  useEffect(() => {
    checkBuildId()
  }, [checkBuildId, router.pathname])

  return null
}

export function useDebounceFunc(cb: Function, delay: number, deps: any[]) {
  const isMounted = useIsMounted()
  const timeoutId = useRef<any>(null)
  const inputsRef = useRef({cb, delay}) // mutable ref like with useThrottle

  useEffect(() => {
    inputsRef.current = {cb, delay}
  }, [cb, delay])

  return useCallback(
    (...args) => {
      clearTimeout(timeoutId.current)
      if (!isMounted.current) return

      timeoutId.current = setTimeout(() => {
        inputsRef.current.cb(...args)
      }, inputsRef.current.delay)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, isMounted, inputsRef],
  )
}

export const useWatch = <T extends unknown>(
  value: T,
  callback = (previousValue: T, newValue: T) => {},
) => {
  const ref = useRef<T | null>(null)

  useEffect(() => {
    ref.current = value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const triggerCallback = async (newValue: any, previousValue: any) => {
      await callback(newValue, previousValue)
      ref.current = value // wait callback to execute first
    }
    triggerCallback(value, ref.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])
}

//allow to have a state for re-render and a ref when we need the latest value right now, useful for random case when we need to update state and call function to have latest value right now
export const useStateRef = <T extends unknown>(initialState: T) => {
  const isMounted = useIsMounted()
  const [state, setState] = useState<T>(initialState)
  const ref = useRef(initialState)

  const _setState = (state: T) => {
    if (!isMounted.current) return
    ref.current = state //update right now so its the latest value on next render
    setState(state)
  }

  // Use "as const" below so the returned array is a proper tuple
  return [state, _setState, ref] as const
}
