import {makeStyles} from '@material-ui/core/styles'
export const useStylesSteps = makeStyles(
  ({palette, spacing, breakpoints, shadows}) => ({
    container: {
      maxWidth: 1600,
    },
    root: {
      flexGrow: 1,
      width: '100%',
    },
    isLoading: {
      pointerEvents: 'none',
      opacity: 0.5,
      position: 'relative',
      userSelect: 'none',
    },
    circularProgress: {
      position: 'absolute',
      inset: 0,
    },
    personalInfo: {
      boxShadow: shadows[1],
      '& p': {
        color: palette.grey[900],
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '32px',
      },
    },
    connectionSect: {
      [breakpoints.down(660)]: {
        display: 'block',
      },
    },
    noPadding: {
      padding: 0,
    },
    buttonSave: {
      background: palette.primary.main,
      borderRadius: spacing(0.75),
      color: palette.common.white,
      padding: spacing(1),
    },
    tabContain: {
      display: 'flex',
      height: '80vh',
      [breakpoints.down(767)]: {
        display: 'block',
      },
    },
    tabs: {
      margin: spacing(3, 0, 0, 4),
      '& button': {
        minWidth: 125,
      },
    },
    addPayment: {
      cursor: 'pointer',
      borderRadius: spacing(1.5),
      width: 264,
      padding: spacing(4.75, 0, 4, 0),
      marginBottom: 0,
      textAlign: 'center',
      backgroundColor: palette.grey[100],
      [breakpoints.down(992)]: {
        marginTop: spacing(2.5),
        marginBottom: spacing(3),
      },
      [breakpoints.down(425)]: {
        width: 'initial',
      },
    },
    containMobile: {
      paddingTop: 0,
      [breakpoints.down(425)]: {
        padding: '0',
        '& #scrollable-auto-tabpanel-0': {
          '& .MuiBox-root': {
            paddingLeft: 0,
            paddingRight: 0,
          },
          '& div': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
    tabMobile: {
      [breakpoints.down(767)]: {
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    masterCard: {
      width: spacing(3),
      height: spacing(1.75),
      background: palette.common.white,
    },
    /*  cardContain: {
      padding: `${spacing(2)}px !important`,
      '& h6': {
        fontWeight: 500,
        fontSize: 14,
      },
    },

    editBtn: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: spacing(1.25, 1.5, 0, 0.5),
      width: '100%',
      height: 44,
      background: palette.common.white,
      border: `1px solid ${palette.grey[200]}`,
      boxSizing: 'border-box',
      borderRadius: spacing(3),
    },

 */
    paymentIcon: {
      margin: spacing(35 / 8, 0, 2.5, 0),
      '& img': {
        width: spacing(2.25),
        height: spacing(2.75),
      },
    },
    addIcon: {
      margin: spacing(0, 'auto', 4, 'auto'),
      width: spacing(2.5),
      height: spacing(2.5),
    },
    taboneDetail: {
      marginTop: spacing(5),
    },
    tabTwoDetail: {
      boxShadow: `-1px 0 0 ${palette.grey[200]}`,
      padding: spacing(0, 0, 0, 5),
      [breakpoints.down(767)]: {
        boxShadow: 'none',
        padding: 0,
      },
    },
    addModal: {
      gap: spacing(2),
    },
    btnCancel: {
      width: '50%',
      height: spacing(5),
    },
    btnSave: {
      width: '50%',
      color: `${palette.common.white} !important`,
      height: spacing(5),
    },
    paymentPara: {
      width: '100%',
      maxWidth: 550,
    },
    paymentTitles: {
      '& h6': {
        fontSize: 16,
        marginTop: spacing(3),
        fontWeight: 500,
        letterSpacing: '0.5px',
      },
    },
    btnaddPayment: {
      background: palette.primary.main,
      borderRadius: spacing(0.75),
      color: palette.common.white,
      width: 264,
      padding: spacing(1),
      '&:hover': {
        background: palette.primary.main,
        color: palette.common.white,
      },
    },
    faqIcon: {
      '& img': {
        height: spacing(2.5),
        width: spacing(2.5),
      },
    },
    accordionData: {
      '& .MuiAccordion-root': {
        boxShadow: 'none',
        background: 'transparent',
      },
      '& .MuiAccordionSummary-content': {
        '& p': {
          fontSize: 14,
        },
      },
      '& .MuiAccordionDetails-root': {
        '& p': {
          fontSize: 14,
          color: palette.grey[500],
          fontWeight: 'normal',
        },
      },
      '& .MuiAccordionSummary-expandIcon': {
        color: palette.primary.main,
      },
    },
    textField: {
      marginBottom: spacing(3),
      '& .MuiTextField-root': {
        width: '100%',
        border: `1px solid ${palette.grey[200]}`,
        borderRadius: spacing(0.75),
        padding: spacing(5 / 8, 15 / 8, 5 / 8, 35 / 8),
        '& .MuiInput-underline': {
          color: palette.grey[700],
          fontSize: 14,
          fontWeight: 'normal',
          '&:before': {
            borderBottom: 'none',
          },
        },
      },
    },
    btnBack: {
      border: `1px solid ${palette.grey[200]}`,
      borderRadius: spacing(0.75),
      color: palette.grey[600],
      height: spacing(5),
      marginRight: spacing(2),
      [breakpoints.down(660)]: {
        width: '100%',
        maxWidth: 73,
        minWidth: 73,
        marginTop: spacing(2.5),
      },
    },
    btnNext: {
      background: palette.primary.main,
      borderRadius: spacing(0.75),
      color: palette.common.white,
      padding: spacing(1, 6),
      [breakpoints.down(660)]: {
        width: '100%',
        marginTop: spacing(2.5),
        maxWidth: 238,
        minWidth: 238,
      },
      '&:hover': {
        background: palette.primary.main,
        color: palette.common.white,
      },
    },
    accountTitle: {
      '& p': {
        fontSize: 14,
        marginTop: spacing(2),
      },
      '& input': {
        border: `1px solid ${palette.grey[200]}`,
        borderRadius: spacing(3),
        fontSize: 14,
        width: '100%',
        padding: spacing(1, 2),
        outline: 'none',
        '&::placeholder': {
          color: palette.grey[400],
        },
      },
    },
    lockIcon: {
      position: 'absolute',
      top: spacing(26 / 8),
      left: spacing(0.5),
      '& img': {
        width: spacing(2),
        height: spacing(18 / 8),
      },
    },
    stripeElement: {
      padding: spacing(1.9, 2.5),
      border: `1px solid ${palette.grey[200]}`,
      borderRadius: '3px',
    },
    cardActions: {
      marginTop: spacing(3.5),
      gap: spacing(1.5),
    },
    editCardBtn: {
      color: palette.primary.main,
      padding: spacing(0, 0),
      minWidth: 'unset',
    },
    deleteCardBtn: {
      color: palette.error.main,
      padding: spacing(0, 0),
      minWidth: 'unset',
    },
    divider: {
      height: spacing(1),
      width: 1,
      border: `1px solid ${palette.divider}`,
    },
    editCardInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: palette.grey[100],
      borderRadius: '3px',
      padding: spacing(2, 3),
    },
  }),
)
