import {http} from 'src/services/http'

export async function getCustomerInformationApi(userId?: number) {
  const data = await http.get(`/stripe/customer`, {
    params: {
      user_id: userId,
    },
  })
  return data
}

export async function createCustomerApi(userId?: number) {
  const data = await http.post(`/stripe/customer`, {
    user_id: userId,
  })
  return data
}

export async function addPaymentMethodApi(
  paymentMethodId: string,
  userId?: number,
) {
  const data = await http.post(`/stripe/payment_methods`, {
    payment_method_id: paymentMethodId,
    user_id: userId,
  })
  return data
}

export async function getPaymentMethodsApi(userId?: number) {
  const data = await http.get(`/stripe/payment_methods`, {
    params: {
      user_id: userId,
    },
  })
  return data
}

export async function deletePaymentMethodApi(
  paymentMethodId: string,
  userId?: number,
) {
  const data = await http.delete(`/stripe/payment_methods`, {
    params: {
      card_id: paymentMethodId,
      user_id: userId,
    },
  })
  return data
}

export async function addAccountApi() {
  const {data} = await http.post(`/stripe/account`)
  return data
}

export async function getConnectedAccountsApi() {
  const {data} = await http.get(`/stripe/accounts`)
  return data
}

export async function retrieveAccountApi(accountId: any) {
  const {data} = await http.get(`/stripe/retrieve_account`, {
    params: {
      account_id: accountId,
    },
  })
  return data
}

export async function deleteAccountApi() {
  const {data} = await http.delete(`/stripe/bank_account`)
  return data
}

export async function updateAccountApi(updateData: any) {
  const {data} = await http.put(`/stripe/bank_account`, updateData)
  return data
}

export async function updatePaymentMethodApi(searchData: {
  paymentMethodId: string
  expYear?: string
  expMonth?: string
  name?: string
  main?: boolean
  user_id?: number
}) {
  const {data} = await http.put(`/stripe/payment_methods`, searchData)
  return data
}

export async function getLoginLinkApi() {
  const {data} = await http.get(`/stripe/create_login_link`)
  return data
}

export async function getMyTransfersApi() {
  const {data} = await http.get(`/transfers`)
  return data
}

export async function adminGetAllTransfersApi() {
  const {data} = await http.get(`/admin/transfers`)
  return data
}
