import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Switch, {SwitchProps} from '@material-ui/core/Switch'

const useStyles = makeStyles(({palette, transitions}) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(18px)',
      color: `${palette.common.white} !important`,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: '0.75rem',
    border: `none`,
    backgroundColor: palette.error.dark,
    opacity: 1,
    transition: transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))

const EnhancedSwitch = ({className, ...props}: SwitchProps) => {
  const classes = useStyles()
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: clsx(classes.root, className),
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
}

export default EnhancedSwitch
