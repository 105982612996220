import thunk from 'redux-thunk'
import {createStore, applyMiddleware, compose, Store} from 'redux'
import rootReducer from 'src/store/reducers/rootReducer'
import {isClient} from 'src/utils/dom'

let middleware = applyMiddleware(thunk)
if (process.env.NODE_ENV !== 'production') {
  // middleware = applyMiddleware(thunk, require('redux-logger').default) //add logger only on dev
}

//update those keys when you update the version of the store
const OLD_VERSION_KEYS = ['chalet-root', 'chalet-root-v-1.0.0']
const VERSION_KEY = 'chalet-root-v-1.0.1'

// Persisting Redux Store as Next.js refresh pages and we need to maintain state...
let devtools, store: Store

if (isClient) {
  const composeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
  const identity = <T>(t: T) => t
  devtools = process.browser && composeEnhancers ? composeEnhancers() : identity // identity function

  const {persistStore, persistReducer} = require('redux-persist')
  const storage = require('redux-persist/lib/storage').default

  //make sure to remove old keys so we dont have trash on the browser
  for (const oldVersionKey of OLD_VERSION_KEYS) {
    storage.removeItem(`persist:${oldVersionKey}`)
  }

  const persistConfig = {
    key: VERSION_KEY,
    storage,
    blacklist: ['propertySearch', 'global', 'badge'],
  }

  store = createStore(
    persistReducer(persistConfig, rootReducer),
    compose(middleware, devtools),
  )

  // store.__PERSISTOR = persistStore(store);

  persistStore(store)
} else {
  store = createStore(rootReducer, compose(middleware))
}

export default store

export type RootState = ReturnType<typeof store.getState>
