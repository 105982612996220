import {AnyAction} from 'redux'
import {
  LOGOUT,
  SET_BOOKING_BADGE,
  SET_MESSAGE_BADGE,
  SET_PROPERTY_BADGE,
} from 'src/store/actionTypes'
import {dayjs} from 'src/utils/date'

type BadgeStateType = {
  message: {
    host_badge: number
    guest_badge: number
    last_updated_at: string
  }
  booking: {
    host_badge: number
    guest_badge: number
    last_updated_at: string
  }
  property: {
    citq_badge: number
    last_updated_at: string
  }
}
const initialState: BadgeStateType = {
  message: {
    host_badge: 0,
    guest_badge: 0,
    last_updated_at: dayjs().format(),
  },
  booking: {
    host_badge: 0,
    guest_badge: 0,
    last_updated_at: dayjs().format(),
  },
  property: {
    citq_badge: 0,
    last_updated_at: dayjs().format(),
  },
}

const badgeReducer = (
  state = initialState,
  action: AnyAction,
): BadgeStateType => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...initialState,
      }
    }
    case SET_BOOKING_BADGE: {
      return {
        ...state,
        booking: {
          ...action.payload,
          last_updated_at: dayjs().format(),
        },
      }
    }
    case SET_MESSAGE_BADGE: {
      return {
        ...state,
        message: {
          ...action.payload,
          last_updated_at: dayjs().format(),
        },
      }
    }
    case SET_PROPERTY_BADGE: {
      return {
        ...state,
        property: {
          ...action.payload,
          last_updated_at: dayjs().format(),
        },
      }
    }
    default:
      return state
  }
}

export default badgeReducer
