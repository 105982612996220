import {signOut} from 'next-auth/client'
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  GET_ME_SUCCESS,
  GET_ME_FAIL,
  GET_ME,
  SWITCH_MENU,
  SET_SOCIAL_CONNECT_ERROR,
  LOGIN_BY_TOKEN,
} from 'src/store/actionTypes'
import {
  publicLoginUserApi,
  logoutUserApi,
  getMeApi,
  publicSocialLoginApi,
  publicLoginByTokenApi,
} from 'src/services/api/auth'
import * as Sentry from '@sentry/nextjs'
import {LoginFormValues} from 'src/types/form'

export const loginAction = (payload: LoginFormValues) => {
  return (dispatch: any) => {
    dispatch({
      type: LOGIN,
    })

    return publicLoginUserApi(payload)
      .then((result: any) => {
        dispatch({
          payload: result,
          type: LOGIN_SUCCESS,
        })
      })
      .catch((ex) => {
        let payload = null
        const error = ex.response?.data
        if (error) {
          payload = error.message
        } else {
          payload = ex.message
        }

        dispatch({
          type: LOGIN_FAIL,
          payload: payload,
        })
      })
  }
}

export const loginByTokenAction = (payload: {token: string}) => {
  return (dispatch: any) => {
    dispatch({
      type: LOGIN_BY_TOKEN,
    })

    return publicLoginByTokenApi(payload.token)
      .then((result) => {
        dispatch({
          payload: result,
          type: LOGIN_SUCCESS,
        })
      })
      .catch((ex) => {
        let payload = null
        const error = ex.response?.data
        if (error) {
          payload = error.message
        } else {
          payload = ex.message
        }

        dispatch({
          type: LOGIN_FAIL,
          payload: payload,
        })
      })
  }
}

export const getMeAction = () => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ME,
    })

    getMeApi()
      .then((result) => {
        dispatch({
          payload: result,
          type: GET_ME_SUCCESS,
        })
      })
      .catch(() => {
        dispatch({
          type: GET_ME_FAIL,
        })
      })
  }
}

export const updateUserAction = (user: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ME,
    })

    dispatch({
      payload: user,
      type: GET_ME_SUCCESS,
    })
  }
}

export const logoutAction = () => {
  logoutUserApi()

  return {
    type: LOGOUT,
  }
}

export const socialLoginAction = (payload: LoginFormValues) => {
  return (dispatch: any) => {
    dispatch({
      type: LOGIN,
    })

    return publicSocialLoginApi(payload)
      .then((result: any) => {
        dispatch({
          payload: result,
          type: LOGIN_SUCCESS,
        })
        if (result.user.role === 3) {
          dispatch({
            type: SWITCH_MENU,
            payload: 'host',
          })
        }
      })
      .catch((ex) => {
        Sentry.captureException(ex)
        signOut({redirect: false})

        let payload = null
        const error = ex.response?.data
        if (error) {
          payload = error.message
        } else {
          payload = ex.message
        }

        dispatch({
          type: LOGIN_FAIL,
          payload: payload,
        })
      })
  }
}

export const setMenuAction = (payload: 'guest' | 'host') => {
  return {
    type: SWITCH_MENU,
    payload,
  }
}

export const setSocialConnectError = (payload: any) => {
  return {
    type: SET_SOCIAL_CONNECT_ERROR,
    payload,
  }
}
