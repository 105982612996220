import {useState} from 'react'
import {useTranslation} from 'next-i18next'
import AppButton from 'src/components/elements/buttons/AppButton'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {
  addUserPhoneNumberApi,
  resendVerificationApi,
  verifyPhoneUserApi,
} from 'src/services/api/auth'
import PhoneInput from 'react-phone-input-2'
import InputError from 'src/components/elements/helpers/InputError'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import {useDispatch} from 'react-redux'
import {getMeAction} from 'src/store/actions/userAction'
import {handleHttpException} from 'src/services/http'
import {formatPhoneNumberIntl} from 'react-phone-number-input'
import Error from 'src/components/elements/helpers/Error'
import ActionButtonsGroup from 'src/components/elements/buttonGroups/ActionButtonsGroup'
import dynamic from 'next/dynamic'
import {Label} from 'src/components/forms/textField/AppTextField'

const ReactPinField = dynamic(() => import('react-code-input'))

const pinFieldProps = {
  inputStyle: {
    fontFamily: 'monospace',
    margin: 4,
    width: 50,
    height: 42,
    fontSize: 24,
    borderRadius: 6,
    border: '1px solid gray',
  },
  inputStyleInvalid: {
    fontFamily: 'monospace',
    margin: 4,
    width: 50,
    height: 42,
    fontSize: 24,
    borderRadius: 6,
    border: '1px solid gray',
  },
}

export default function FormStep3(props: {
  onCompleted: () => void
  token: string
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  const [pin, setPin] = useState<string>('')

  const {t} = useTranslation(['property', 'booking', 'auth'])
  const dispatch = useDispatch()

  const resendVerification = () => {
    setLoading(true)
    setSuccess(false)
    resendVerificationApi(phoneNumber)
      .then(() => setSuccess(true))
      .finally(() => setLoading(false))
  }

  const onVerify = () => {
    if (pin.length === 4) {
      setLoading(true)
      setSuccess(false)
      verifyPhoneUserApi({
        phone_number: phoneNumber,
        token: pin,
      })
        .then(() => {
          dispatch(getMeAction())
          props.onCompleted()
        })
        .catch(handleHttpException(setError, t))
        .finally(() => setLoading(false))
    }
  }

  const handleAddPhoneNumber = () => {
    setLoading(true)
    setError(null)
    addUserPhoneNumberApi({
      phone_number: phoneNumber,
      is_primary: true,
      is_for_guests: false,
    })
      .then((data) => {
        setVerifyPhoneNumber(true)
      })
      .catch(handleHttpException(setError, t))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <div className="mb-4">
        <AppTypography variant="caption">
          {t('onboarding_caption_3', {ns: 'auth'})}
        </AppTypography>
      </div>
      {verifyPhoneNumber ? (
        <>
          <AppTypography
            className="text-center md:text-left"
            variant="action"
            neutralColor={900}
          >
            {`${t('We sent the code by SMS to', {
              ns: 'property',
            })} ${formatPhoneNumberIntl(`+${phoneNumber}`)}`}
          </AppTypography>
          <AppTypography
            className="text-center md:text-left"
            variant="body"
            neutralColor={600}
          >
            {t('sms_description')}
          </AppTypography>
          <div className="flex flex-col justify-center items-center">
            <ReactPinField
              fields={4}
              type="number"
              value={pin}
              name="pin"
              inputMode="numeric"
              onChange={setPin}
              {...pinFieldProps}
            />
          </div>
          <div className="my-4 flex flex-row justify-end items-center gap-2">
            <AppTypography neutralColor={400} variant="body">
              {`${t('you_did_not_receive_it', {ns: 'property'})}?`}
            </AppTypography>
            <AppButton
              color="primary"
              onClick={resendVerification}
              disabled={loading}
            >
              {t('Try again', {ns: 'property'})}
            </AppButton>
          </div>
          <Success success={success} />
          <Error error={error} />
          <ActionButtonsGroup
            primaryBtnProps={{
              disabled: loading || pin.length !== 4,
              children: t('Confirm', {ns: 'property'}),
              onClick: onVerify,
            }}
            secondaryBtnProps={{
              children: t('Cancel', {ns: 'property'}),
              startIcon: null,
              onClick: () => setVerifyPhoneNumber(false),
            }}
          />
        </>
      ) : (
        <div className="flex flex-col gap-6">
          <AppTypography variant="action" neutralColor={500}>
            {t('add_a_phone_number_description', {ns: 'booking'})}
          </AppTypography>
          <div>
            <Label name="phone" label={t('Phone Number', {ns: 'booking'})} />
            <PhoneInput
              country="ca"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
            <InputError>{error}</InputError>
          </div>
          <div className="flex flex-row justify-end items-start gap-4">
            <AppRoundedButton
              variant="contained"
              color="primary"
              onClick={handleAddPhoneNumber}
              disabled={!phoneNumber || loading}
            >
              {t('Send', {ns: 'booking'})}
            </AppRoundedButton>
          </div>
        </div>
      )}
    </>
  )
}

function Success(props: {success: boolean}) {
  const {t} = useTranslation('auth')
  if (!props.success) {
    return null
  }

  return (
    <div className="flex justify-end mb-6">
      <AppTypography variant="body" neutralColor={600}>
        {t('We send the new code to your phone number')}
      </AppTypography>
    </div>
  )
}
